import React, { useState } from 'react';
import { AudioIcon, PresentationIcon, VideoIcon } from '../../../../../assets/icons';

export type IPresentationTabs = 'presentation' | 'video' | 'audio';
interface ITabs {
  title: string;
  id: IPresentationTabs;
  icon: JSX.Element;
}

const tabs: ITabs[] = [
  {
    title: 'Presentation',
    id: 'presentation',
    icon: <PresentationIcon />
  },
  {
    title: 'Video',
    id: 'video',
    icon: <VideoIcon />
  },
  {
    title: 'Audio',
    id: 'audio',
    icon: <AudioIcon />
  }
];

export const usePresentationTabs = () => {
  const [activeTab, setActiveTab] = useState<IPresentationTabs>('presentation');

  return { tabs, activeTab, setActiveTab };
};
