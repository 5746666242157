import styled from 'styled-components';
import { PandaIcon } from '../../../assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignUpButton, SignUpContent, SignUpTitle, SignUpWrapper } from '../../../assets/styles/Styled';
import { SignUpLayout } from '../../../components/SignUpLayout';
import { resendEmail } from '../../../api/apiWorker';
import { message } from 'antd';
import { useEffect, useState } from 'react';

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #233269;

  > span {
    font-weight: 600;
  }
`;

const CustomWrapper = styled(SignUpWrapper)`
  @media (max-width: 1280px) {
    margin-top: -226px;
  }
`;

const SignUpSuccess = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { account_created } = location.state || {};

  const email = localStorage.getItem('signUpEmail');

  useEffect(() => {
    if (account_created === 'faild') {
      message.error('Oops! Something went wrong with the verification link. Please resend the email to try again.');
    }
  }, [account_created]);

  const goToLogin = () => {
    navigate('/login');
  };

  const resend = async () => {
    try {
      setLoading(true);
      if (email) {
        await resendEmail(email);
        message.success('Email successfully sent! Please check your inbox');
      }
    } catch (e) {
      message.error('Failed to resend the email. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SignUpLayout>
      <CustomWrapper>
        <SignUpContent>
          <PandaIcon />
          <SignUpTitle>Account Successfully Created</SignUpTitle>
          <Text>
            We`ve send you verification email to <span>{`${email}`}</span>. Please, confirm your account and come back
          </Text>
          <SignUpButton type="button" onClick={goToLogin}>
            Log In
          </SignUpButton>
          <SignUpButton disabled={loading} type="button" onClick={resend}>
            Resend Email
          </SignUpButton>
        </SignUpContent>
      </CustomWrapper>
    </SignUpLayout>
  );
};

export default SignUpSuccess;
