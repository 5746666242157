import { useCallback, useMemo } from 'react';
import { LoItem, LoItemToPreview, NewActionsItem, Sections } from '../../../types';
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from '../../../context/AppContext';
import { ImageActions } from '../sections/sections.styled';

export const useLoAction = ({
  blockId,
  newActions,
  liveObjects,
  liveObjectsBasePath,
  setNewActions,
  setLoToPreview
}: {
  blockId: string;
  liveObjects: string[];
  liveObjectsBasePath: string;
  newActions: NewActionsItem[];

  setNewActions: React.Dispatch<React.SetStateAction<NewActionsItem[]>>;
  setLoToPreview: React.Dispatch<React.SetStateAction<LoItemToPreview>>;
}) => {
  const { liveObjectWithId } = useAppContext();

  const updateLoActionsItem = ({
    prev,
    blockId,
    live_object_id,
    dropdownKey,
    uniq_id,
    actionType
  }: {
    prev: { [key: string]: LoItem[] };
    blockId: string;
    live_object_id: string;
    dropdownKey: string;
    uniq_id: string;
    actionType: string;
  }): { [key: string]: LoItem[] } => {
    const currentBlockActions: LoItem[] = prev[blockId] || [];
    const actionIndex = currentBlockActions.findIndex(action => action.dropdownKey === dropdownKey);

    const updatedActions: LoItem[] =
      actionIndex !== -1
        ? currentBlockActions.map((action, idx) =>
            idx === actionIndex
              ? {
                  dropdownKey: dropdownKey,
                  live_object_id: live_object_id || '',
                  position_x: action.position_x || 0.35,
                  position_y: action.position_y || 0,
                  size: action.size || 0.3,
                  uniq_id: action.uniq_id ? action.uniq_id : uniq_id,
                  action_type: actionType
                }
              : action
          )
        : [
            ...currentBlockActions,
            {
              dropdownKey: dropdownKey,
              live_object_id: live_object_id,
              position_x: 0.35,
              position_y: 0,
              size: 0.3,
              uniq_id: uniq_id,
              action_type: actionType
            }
          ];

    return {
      ...prev,
      [blockId]: updatedActions
    };
  };

  const handleLoActionClick = useCallback(
    (live_object_id: string, dropdownKey: string, actionType: string) => {
      const uniqueId = uuidv4();
      setNewActions(prev =>
        prev.map(item => {
          return item.dropdownKey === dropdownKey
            ? {
                ...item,
                position_x: 0.35,
                position_y: 0,
                size: 0.3,
                uniq_id: item.uniq_id ? item.uniq_id : uniqueId,
                live_object_id: live_object_id
              }
            : item;
        })
      );
      setLoToPreview(prev => {
        return updateLoActionsItem({ prev, blockId, live_object_id, dropdownKey, uniq_id: uniqueId, actionType });
      });
    },
    [newActions]
  );

  const menuItemsLoActions = useMemo(() => {
    if (!liveObjects.length) return [];
    return liveObjects.map(item => ({
      key: liveObjectWithId?.find(lo => lo.name === item)?.object_id || item,
      label: <ImageActions key={item} src={`${liveObjectsBasePath}${item}.png`} alt={item} />
    }));
  }, [liveObjectsBasePath, liveObjects]);

  return {
    menuItemsLoActions,
    updateLoActionsItem,
    handleLoActionClick
  };
};
