import { CrownIcon } from '../../assets/icons';
import { SignUpForm } from './signUpForm/SignUpForm';
import { SignUpContent, SignUpLink, SignUpTitle, SignUpWrapper } from '../../assets/styles/Styled';
import { SignUpLayout } from '../../components/SignUpLayout';

const SignUpPage = () => {
  return (
    <SignUpLayout>
      <SignUpWrapper>
        <SignUpContent>
          <CrownIcon />
          <SignUpTitle>Become MaPander</SignUpTitle>
          <SignUpForm />
        </SignUpContent>
        <SignUpLink to="/login">Log In</SignUpLink>
      </SignUpWrapper>
    </SignUpLayout>
  );
};
export default SignUpPage;
