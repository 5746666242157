import React from 'react';
import { SuccessIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { SignUpLayout } from '../../../components/SignUpLayout';
import { SignUpButton, SignUpContent, SignUpTitle, SignUpWrapper } from '../../../assets/styles/Styled';
import styled from 'styled-components';

const CustomWrapper = styled(SignUpWrapper)`
  margin-top: -180px;
  @media (max-width: 1280px) {
    margin-top: -120px;
  }
`;

const ChangePasswordSuccess = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };
  return (
    <SignUpLayout>
      <CustomWrapper>
        <SignUpContent>
          <SuccessIcon />
          <SignUpTitle>Password successfully changed</SignUpTitle>

          <SignUpButton type="button" onClick={goToLogin}>
            Log In
          </SignUpButton>
        </SignUpContent>
      </CustomWrapper>
    </SignUpLayout>
  );
};

export default ChangePasswordSuccess;
