import { Form, message, Select } from 'antd';
import { useMemo, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import styled from 'styled-components';
import { ChangeVersionFormLabel } from '../../components/Antd/FormLabel';
import { EditBtn } from '../../assets/styles/Styled';

interface FormValues {
  organization: string;
}
const Container = styled.div`
  width: 100%;
  padding: 0 12px;
`;
export const ChangeOrganizationForm = ({
  selectedOrg = '',
  updateOrganization
}: {
  selectedOrg: string;
  updateOrganization: (orgId: string) => Promise<void>;
}) => {
  const { organizations } = useAppContext();

  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm<FormValues>();
  const formValues = Form.useWatch<FormValues>(undefined, form);

  const disableSubmit = useMemo(() => {
    return loading || !formValues?.organization || formValues?.organization === selectedOrg;
  }, [loading, formValues, selectedOrg]);

  const organizationOptions = useMemo(() => {
    return organizations.map(org => ({
      label: org.organization_name,
      value: org.organization_id
    }));
  }, [organizations]);

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      await updateOrganization(values.organization);
      message.success('Organization was successfully updated');
    } catch (e) {
      message.error('Unable to update Organization');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form
        name="organization"
        className="w-100"
        layout="vertical"
        initialValues={{
          organization: selectedOrg
        }}
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Form.Item
          label={<ChangeVersionFormLabel text="Select Default Organization" />}
          name="organization"
          rules={[{ required: true, message: 'Organization is required' }]}
        >
          <Select size="large" optionLabelProp="label" placeholder="Select organization..." options={organizationOptions} />
        </Form.Item>

        <EditBtn className="btn-orange w-100 mt-4 mr-0" type="submit" disabled={disableSubmit}>
          Apply
        </EditBtn>
      </Form>
    </Container>
  );
};
