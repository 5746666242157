import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DeleteFilled } from '@ant-design/icons';
import { Input, Popconfirm, Typography } from 'antd';
import { ArrowUpIcon, CheckCircleIcon, DragIcon, PenIcon } from '../../../../../assets/icons';
import { Scenario, Sections } from '../../../../../types';
import { useScriptForm } from '../../../ScriptCreateStore';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ChapterContent } from './ChapterContent';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{ expanded: boolean }>`
  width: 100%;
  max-height: ${props => (props.expanded ? '2000px' : '76px')};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid var(--light-description);
  background-color: var(--icon-gray);
  transition: all 0.2s ease;
  @media (max-width: 450px) {
    padding: 8px;
  }
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  gap: 8px;
`;

const Header = styled.div<{ newChapter?: boolean; hasError: boolean }>`
  height: ${props => (props.newChapter ? '48px' : '40px')};
  width: 100%;
  padding: ${props => (props.newChapter ? '4px 16px' : '0 16px')};
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: var(--hint-color);
  border-radius: 16px;
  border: ${props => (props.hasError ? '2px solid var(--text-red)' : 'none')};
`;

const StyledInput = styled(Input)`
  background: transparent;
  border: none;
  padding: 0;
  font-size: 20px;
  font-weight: 500;

  &:active,
  &:hover,
  &:focus {
    background: transparent;
    border: none;
    box-shadow: none;
  }
`;

const ArrowIcon = styled(ArrowUpIcon)<{ expanded: boolean }>`
  color: var(--light-description);
  cursor: pointer;
  transform: ${props => (props.expanded ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

interface Props {
  scenario: Scenario;
  scenarioIndex: number;
  onExpand: (id: string) => void;
  expanded: boolean;
}

export const Chapter: React.FunctionComponent<Props> = ({ scenario, scenarioIndex, expanded, onExpand }) => {
  const {
    formValues: { scenario: scenarioState },
    setFormValues,
    sectionErrors
  } = useScriptForm();
  const { title: chapterTitle } = scenario;
  const [newTitle, setNewTitle] = useState('');
  const [editing, setEditing] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: scenario.id });

  const missingTitle = useMemo(() => {
    return !chapterTitle && sectionErrors.section === Sections.Scenario ? scenarioIndex === sectionErrors.errorId : false;
  }, [chapterTitle, scenarioIndex, sectionErrors]);

  useEffect(() => {
    if (!chapterTitle) setEditing(true);
    setNewTitle(chapterTitle);
  }, [chapterTitle]);

  const handleTitleChange = useCallback(() => {
    const updatedScenario = scenarioState.map(_scenario => {
      return _scenario.id === scenario.id ? { ..._scenario, title: newTitle } : _scenario;
    });
    setFormValues(prevState => ({ ...prevState, scenario: updatedScenario }));
    setEditing(false);
  }, [scenarioState, scenario, newTitle, setFormValues]);

  const handleRemoveChapter = useCallback(() => {
    const updatedScenario = scenarioState.filter(_scenario => _scenario.id !== scenario.id);
    setFormValues(prevState => ({ ...prevState, scenario: updatedScenario }));
  }, [setFormValues, scenarioState, scenario]);

  const draggableStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
    opacity: isDragging ? 0.5 : 1
  };
  return (
    <Wrapper ref={setNodeRef} style={draggableStyle} {...attributes}>
      <Container expanded={expanded}>
        <HeaderWrapper>
          <DragIcon {...listeners} className="light-description cursor-grab" />
          <Header hasError={missingTitle}>
            {editing ? (
              <>
                <StyledInput
                  placeholder="Type chapter title..."
                  maxLength={40}
                  value={newTitle}
                  autoFocus
                  onChange={e => setNewTitle(e.target.value)}
                  onPressEnter={handleTitleChange}
                  onBlur={handleTitleChange}
                />
                <CheckCircleIcon className="regular-32 description-solid cursor-pointer" onClick={handleTitleChange} />
              </>
            ) : (
              <>
                {chapterTitle ? (
                  <Typography.Text ellipsis={{ tooltip: chapterTitle }} className="extrabold-20 primary-text">
                    {chapterTitle}
                  </Typography.Text>
                ) : (
                  <Typography.Text
                    ellipsis
                    style={{ color: 'rgba(0, 0, 0, 0.22)' }}
                    className="normal-20"
                    onClick={() => setEditing(true)}
                  >
                    Type chapter title...
                  </Typography.Text>
                )}

                <PenIcon className="light-description cursor-pointer" onClick={() => setEditing(true)} />
              </>
            )}
            <Popconfirm
              title="Delete this Chapter?"
              okText="Delete"
              okButtonProps={{ danger: true }}
              onConfirm={handleRemoveChapter}
            >
              <DeleteFilled className="regular-20 light-description cursor-pointer" />
            </Popconfirm>
          </Header>
          <ArrowIcon expanded={expanded} onClick={() => onExpand(scenario.id)} />
        </HeaderWrapper>
        <ChapterContent scenario={scenario} expanded={expanded} />
      </Container>
    </Wrapper>
  );
};
