import { SlidesPreviewBox, Uploader } from '../../components';
import { Space } from 'antd';
import { useScriptForm } from '../../ScriptCreateStore';
import styled from 'styled-components';
import { PageContent } from '../../../../assets/styles/Styled';
import { PresentationTabs } from './PresentationTabs/PresentationTabs';
import { usePresentationTabs } from './PresentationTabs/usePresentationTabs';
import { useUploadPresentation } from '../../hooks';
import { useUploadMedia } from '../../hooks/useUploadMedia';
import { IMedia } from '../../../../types';
import { VideoPreviewer } from '../../../../components/VideoPreviewer';
import { AudioPreviewer } from '../../../../components/AudioPreviewer';

const Container = styled(PageContent)`
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PresentationSection = () => {
  const { formValues, setFormValues, readOnlyMode } = useScriptForm();
  const { activeTab, setActiveTab } = usePresentationTabs();

  const { uploadPropsPresentation, uploadedFiles, progress } = useUploadPresentation({
    onChange: images => setFormValues(prev => ({ ...prev, slides: [...formValues.slides, ...images] })),
    isShowUploader: !!formValues.slides.length
  });

  const {
    progressMedia: progressVideo,
    uploadPropsMedia: uploadPropsVideo,
    uploadedMediaFiles: uploadedVideoFiles,
    currentUploadedFile,
    setUploadedFiles: setUploadedFilesVideo,
    retryFailedUploads: retryFailedUploadsVideo,
    cancelDownloadFile: cancelUploadVideo
  } = useUploadMedia({
    onChange: (video: IMedia) => setFormValues(prev => ({ ...prev, video: [...prev.video, video] })),
    type: 'video',
    isShowUploader: !!formValues.video.length,
    acceptFormats: '.mp4,.webm,.ogg,.mov'
  });
  const {
    progressMedia: progressAudio,
    uploadPropsMedia: uploadPropsAudio,
    uploadedMediaFiles: uploadedAudioFiles,
    currentUploadedFile: currentUploadedFileAudio,
    setUploadedFiles,
    retryFailedUploads: retryFailedUploadsAudio,
    cancelDownloadFile: cancelUploadAudio
  } = useUploadMedia({
    onChange: (audio: IMedia) => setFormValues(prev => ({ ...prev, audio: [...prev.audio, audio] })),
    type: 'audio',
    isShowUploader: !!formValues.audio.length,
    acceptFormats: '.mp3'
  });

  return (
    <Container>
      <Space className="w-100" direction="vertical" size={24}>
        <PresentationTabs setActiveTab={setActiveTab} activeTab={activeTab} />
        {activeTab === 'presentation' && (
          <>
            <Uploader
              isShowUploadBtn={!!formValues.slides.length}
              uploadProps={uploadPropsPresentation}
              disabled={readOnlyMode}
              uploadedFiles={uploadedFiles}
              progress={progress}
              uploadConfig={{
                title: 'Upload a presentation',
                filesFormats: '(Png, Jpg, Pdf)',
                subTitle: 'The total size of files should be less than 5mb and contain less than 60 slides'
              }}
              type="presentation"
            />
            {!!formValues.slides.length && (
              <SlidesPreviewBox
                readonly={readOnlyMode}
                slides={formValues.slides}
                onChange={slides => setFormValues(prev => ({ ...prev, slides }))}
                setFormValues={setFormValues}
              />
            )}
          </>
        )}
        {activeTab === 'video' && (
          <>
            <Uploader
              isShowUploadBtn={!!formValues.video.length}
              uploadProps={uploadPropsVideo}
              disabled={readOnlyMode}
              uploadedFiles={uploadedVideoFiles}
              progress={progressVideo}
              uploadConfig={{
                title: 'Upload a video',
                filesFormats: '(Mp4, Mov)',
                subTitle: 'The file should be less than 500mb'
              }}
              isHideBtnAdd={true}
              type="video"
              retryFailedUploads={retryFailedUploadsVideo}
              cancelDownloadFile={cancelUploadVideo}
              currentUploadedFile={currentUploadedFile}
            />

            {!!formValues.video.length && (
              <VideoPreviewer
                videos={formValues.video}
                uploadProps={uploadPropsVideo}
                onChange={(video: IMedia[]) => setFormValues(prev => ({ ...prev, video }))}
                setFormValues={setFormValues}
                setUploadedFiles={setUploadedFilesVideo}
              />
            )}
          </>
        )}
        {activeTab === 'audio' && (
          <>
            <Uploader
              isShowUploadBtn={!!formValues.audio.length}
              uploadProps={uploadPropsAudio}
              disabled={readOnlyMode}
              uploadedFiles={uploadedAudioFiles}
              progress={progressAudio}
              uploadConfig={{
                title: 'Upload an audio',
                filesFormats: '(Mp3)',
                subTitle: 'The file should be less than 500mb'
              }}
              type="audio"
              retryFailedUploads={retryFailedUploadsAudio}
              cancelDownloadFile={cancelUploadAudio}
              currentUploadedFile={currentUploadedFileAudio}
            />

            {!!formValues.audio.length && (
              <>
                <AudioPreviewer
                  audios={formValues.audio}
                  onChange={(audio: IMedia[]) => setFormValues(prev => ({ ...prev, audio }))}
                  setFormValues={setFormValues}
                  setUploadedFiles={setUploadedFiles}
                />
              </>
            )}
          </>
        )}
      </Space>
    </Container>
  );
};
