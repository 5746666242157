import React, { useEffect, useState } from 'react';
import { ActionButtonEnum, IActionsType, IMedia, IPreviewConfig, NewActionsItem } from '../../../../../types';
import { MenuProps } from 'antd';
import { SortableActionItems } from './SortableActionItems';
import { closestCenter, DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { generateUniqueId } from '../../../../../utils/generateUniqId';
import { BlockActions } from '../../sections.styled';
interface IActionsBlock {
  blockId: string;
  openDropdowns: {
    [key: string]: boolean;
  };
  newActions: NewActionsItem[];
  liveObjects: string[];
  selectedAction: string[];
  slides: string[];
  media: IMedia[];
  renderMenuActionsItems: (action_id: IActionsType, idx: number) => MenuProps | undefined;
  handleOpenDropdown: (dropdownKey: string, flag: boolean) => void;
  setSelectedActionsItem: React.Dispatch<React.SetStateAction<string[]>>;
  renderIcon: (actionType: IActionsType, dropdownKey: string) => JSX.Element;
  renderIconAwardsEffects: (dropdownKey: string) => JSX.Element;
  renderTitle: (actionType: string, dropdownKey: string) => JSX.Element | ActionButtonEnum | undefined;
  renderTitleAwardsEffects: (dropdownKey: string) => JSX.Element;
  renderLoMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderWhiteboardMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderMediaMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderIconItemsLo: (actionType: IActionsType, dropdownKey: string) => JSX.Element | undefined;
  renderTitleLo: (dropdownKey: string) => string | undefined;
  handleRemoveAction: (selectedActionIdx: number) => void;
  setNewActions: React.Dispatch<React.SetStateAction<NewActionsItem[]>>;
  setSelectedAction: React.Dispatch<React.SetStateAction<string[]>>;
  setIsDraggingAction: React.Dispatch<React.SetStateAction<boolean>>;
  setPreviewConfig: (value: React.SetStateAction<IPreviewConfig>) => void;
  renderTitleWhiteboard: (dropdownKey: string) => string | JSX.Element;
  renderTitleMedia: (dropdownKey: string) => string | JSX.Element;
  handleSetTimeCode: ({ dropdownKey }: { dropdownKey: string }) => void;
}

export const ActionsBlock: React.FC<IActionsBlock> = React.memo(
  ({
    blockId,
    openDropdowns,
    newActions,
    liveObjects,
    selectedAction,
    slides,
    media,
    renderMenuActionsItems,
    handleOpenDropdown,
    setSelectedActionsItem,
    renderIcon,
    renderIconAwardsEffects,
    renderTitle,
    renderTitleAwardsEffects,
    renderLoMenuActionsItems,
    renderMediaMenuActionsItems,
    renderWhiteboardMenuActionsItems,
    renderIconItemsLo,
    renderTitleLo,
    handleRemoveAction,
    setNewActions,
    setSelectedAction,
    setIsDraggingAction,
    setPreviewConfig,
    renderTitleWhiteboard,
    renderTitleMedia,
    handleSetTimeCode
  }) => {
    const sensors = useSensors(
      useSensor(MouseSensor, {
        activationConstraint: {
          distance: 5
        }
      }),
      useSensor(TouchSensor, {
        activationConstraint: {
          distance: 5,
          delay: 50
        }
      })
    );
    const [keys, setKeys] = useState<string[]>([]);

    useEffect(() => {
      if (selectedAction.length > keys.length) {
        const newKeys = [...keys];

        for (let i = keys.length; i < selectedAction.length; i++) {
          newKeys.push(generateUniqueId());
        }

        setKeys(newKeys);
      }
    }, [selectedAction, keys]);
    const flattenAndUpdateKeys = (nestedArray: NewActionsItem[][]) => {
      return nestedArray.flatMap((group, groupIndex) =>
        group.map(action => {
          const [type] = action.dropdownKey.split('-');
          return {
            ...action,
            dropdownKey: `${type}-${groupIndex}`
          };
        })
      );
    };
    const handleDragStart = () => {
      setIsDraggingAction(true);
    };

    const handleDragEnd = (event: DragEndEvent) => {
      setIsDraggingAction(false);
      const { active, over } = event;
      const oldIndexParsed = active.id && (active.id as string).split('-').reverse()[0];
      const newIndexParsed = over && over.id && (over.id as string).split('-').reverse()[0];
      const oldIndex = Number(oldIndexParsed);
      const newIndex = Number(newIndexParsed);
      if (!newActions[newIndex]?.action_type || !newActions[oldIndex]?.action_type) return;
      if (oldIndex !== newIndex) {
        setSelectedAction(prevSelectedAction => {
          const updated = arrayMove(prevSelectedAction, oldIndex, newIndex);
          return updated;
        });

        const groupedByType: { [key: string]: any[] } = {};
        newActions.forEach(action => {
          const [, index] = action.dropdownKey.split('-');
          if (!groupedByType[index]) {
            groupedByType[index] = [];
          }
          groupedByType[index].push(action);
        });
        const groupedArray = Object.values(groupedByType);
        const updatedByGroup = arrayMove(groupedArray, oldIndex, newIndex);
        const updatedNewActionsByIndex = flattenAndUpdateKeys(updatedByGroup).sort((a, b) => {
          const aIndex = a.dropdownKey ? parseInt(a.dropdownKey.split('-').pop()!, 10) : 0;
          const bIndex = b.dropdownKey ? parseInt(b.dropdownKey.split('-').pop()!, 10) : 0;

          return aIndex - bIndex;
        });
        const copyUpdatedActions = [...updatedNewActionsByIndex];
        const updatedNewActionsReversed = copyUpdatedActions.reverse();
        const currentBackground = updatedNewActionsReversed.find(item => item.action_type === 'backgrounds');
        if (currentBackground?.id) {
          setPreviewConfig(prev => ({
            ...prev,
            backgrounds: currentBackground.id
          }));
        }
        const currentPosition = updatedNewActionsReversed.find(item => item.action_type === 'character_moving_actions');
        if (currentPosition?.id) {
          setPreviewConfig(prev => ({
            ...prev,
            character_moving_actions: currentPosition.id
          }));
        }
        setNewActions(updatedNewActionsByIndex);
        // setNewActionsToPreview(updatedNewActionsByIndex);
      }
    };
    return (
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
        <SortableContext items={keys.map((item, idx) => `${item}-${idx}`)} strategy={verticalListSortingStrategy}>
          <BlockActions>
            {selectedAction.map((item, idx) => {
              const dropdownKey = `${item}-${idx}`;
              if (item !== 'effects') {
                return (
                  <SortableActionItems
                    key={generateUniqueId()}
                    uid={`${keys[idx]}-${idx}`}
                    idx={idx}
                    dropdownKey={dropdownKey}
                    item={item}
                    blockId={blockId}
                    openDropdowns={openDropdowns}
                    newActions={newActions}
                    liveObjects={liveObjects}
                    slides={slides}
                    media={media}
                    renderMenuActionsItems={renderMenuActionsItems}
                    handleOpenDropdown={handleOpenDropdown}
                    setSelectedActionsItem={setSelectedActionsItem}
                    renderIcon={renderIcon}
                    renderIconAwardsEffects={renderIconAwardsEffects}
                    renderTitle={renderTitle}
                    renderTitleAwardsEffects={renderTitleAwardsEffects}
                    renderLoMenuActionsItems={renderLoMenuActionsItems}
                    renderWhiteboardMenuActionsItems={renderWhiteboardMenuActionsItems}
                    renderMediaMenuActionsItems={renderMediaMenuActionsItems}
                    renderIconItemsLo={renderIconItemsLo}
                    renderTitleLo={renderTitleLo}
                    handleRemoveAction={handleRemoveAction}
                    renderTitleWhiteboard={renderTitleWhiteboard}
                    renderTitleMedia={renderTitleMedia}
                    handleSetTimeCode={handleSetTimeCode}
                  />
                );
              }
            })}
          </BlockActions>
        </SortableContext>
      </DndContext>
    );
  }
);
