import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, message, Spin } from 'antd';
import styled from 'styled-components';
import { FooterButtonContainer, PageContent, StyledButton } from '../../assets/styles/Styled';
import { BackLink } from '../../components/BackLink';
import { FormLabel } from '../../components/Antd/FormLabel';
import { FormItemDescription } from '../../components/FormItemDescription';
import { AccountsResource, Organization, User } from '../../types';
import { createOrganization, getOrganizationUsers, updateOrganization } from '../../api/apiWorker';
import { useAppContext } from '../../context/AppContext';
import { areArraysEqual } from '../../helper';
import { SelectOptionsUser, StyledSelect } from '../NewScriptsPage/sections/sections.styled';
import { useAuth } from '../../context/AuthContext';

const Container = styled.div`
  padding-top: 32px;
  width: 100%;
  height: 100%;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`;

const StyledPageContent = styled(PageContent)`
  margin-top: 84px;
  padding: 0 24px 24px 24px;
  max-width: 906px;
  gap: 24px;
  flex: 1;
  overflow-y: auto;
`;

const NewOrganizationPage = () => {
  const { state } = useLocation();
  const { accountsResource, accountsLoading, fetchOrganizations, fetchAccountResources, organizationsLoading } = useAppContext();
  const [orgUsers, setOrgUsers] = useState<AccountsResource[]>([]);
  const [orgAdmins, setOrgAdmins] = useState<AccountsResource[]>([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const [form] = Form.useForm<Organization>();
  const nameField = Form.useWatch('organization_name', form);
  const adminsField = Form.useWatch('admins', form);
  const usersField = Form.useWatch('users', form);
  const adminsRef = useRef<string[]>([]);
  const usersRef = useRef<string[]>([]);
  const isAdmin = user?.user.role === 'admin';

  const handleFetchAccounts = async () => {
    await fetchAccountResources();
  };
  useEffect(() => {
    if (!accountsResource.length) {
      handleFetchAccounts();
    }
  }, [accountsResource]);
  const uniqueById = (arr: AccountsResource[]) => Array.from(new Map(arr.map(item => [item.user_id, item])).values());
  const usersOptions = useMemo(() => {
    const accounts = uniqueById([...accountsResource, ...orgUsers]);
    return accounts
      .filter(acc => acc.status === 'active' && !adminsField?.includes(acc.user_id))
      .map(acc => ({
        label: (
          <SelectOptionsUser>
            {`${acc.first_name || ''} ${acc.last_name || ''}`}
            <span className="tag-email">{acc.email}</span>
          </SelectOptionsUser>
        ),
        value: acc.user_id,
        desc: `${acc.first_name || ''} ${acc.last_name || ''} ${acc.email}`
      }));
  }, [accountsResource, adminsField, orgUsers, isAdmin]);

  const adminsOptions = useMemo(() => {
    const admins = uniqueById([...accountsResource, ...orgAdmins]);

    return admins
      .filter(acc => acc.status === 'active' && !usersField?.includes(acc.user_id))
      .map(acc => {
        return {
          label: (
            <SelectOptionsUser>
              {`${acc.first_name || ''} ${acc.last_name || ''}`}
              <span className="tag-email">{acc.email}</span>
            </SelectOptionsUser>
          ),
          value: acc.user_id,
          desc: `${acc.first_name || ''} ${acc.last_name || ''} ${acc.email}`
        };
      });
  }, [accountsResource, usersField, orgAdmins]);
  const fetchOrganizationUsers = useCallback(
    async (organizationData: Organization) => {
      if (!organizationData.organization_id) return;
      setLoading(true);
      try {
        const { users, admins } = await getOrganizationUsers(organizationData.organization_id);

        const filteredUsers = Array.isArray(users)
          ? users.filter(user => user.status === 'active' && !adminsField?.includes(user.user_id))
          : [];
        const userIds = filteredUsers.map(user => user.user_id);
        const filteredAdmins = Array.isArray(admins)
          ? admins.filter(admin => admin.status === 'active' && !usersField?.includes(admin.user_id))
          : [];
        const adminIds = filteredAdmins.map(admin => admin.user_id);
        setOrgUsers(filteredUsers);
        setOrgAdmins(filteredAdmins);
        adminsRef.current = adminIds;
        usersRef.current = userIds;
        form.setFieldsValue({ ...organizationData, users: userIds, admins: adminIds });
      } catch (e) {
        message.error('Unable to get organization users');
        console.error(e, '[fetchOrganizationUsers]');
      } finally {
        setLoading(false);
      }
    },
    [form]
  );

  useEffect(() => {
    state && fetchOrganizationUsers(state);
  }, [state, fetchOrganizationUsers]);

  const onSubmit = async (formValues: Organization) => {
    const { users, admins, ...values } = formValues;
    if (!areArraysEqual(users, usersRef.current)) {
      Object.assign(values, { users });
    }
    if (!areArraysEqual(admins, adminsRef.current)) {
      Object.assign(values, { admins });
    }

    setLoading(true);
    try {
      if (formValues.organization_id) {
        await updateOrganization(values);
        message.success('Organization was successfully updated');
      } else {
        await createOrganization(values);
        message.success('Organization was successfully created');
      }
    } catch (e) {
      if (formValues.organization_id) {
        message.error('Unable to update Organization');
      } else {
        message.error('Unable to create Organization');
      }
      console.error(e);
    } finally {
      await fetchOrganizations();
      setLoading(false);
      navigate('/organizations');
    }
  };

  return (
    <Container>
      <BackLink title={state ? 'Edit Organization' : 'Create New Organization'} path="/organizations" />
      <StyledPageContent>
        <Spin spinning={loading || accountsLoading || organizationsLoading}>
          <Form form={form} layout="vertical" autoComplete="off" requiredMark={false} onFinish={onSubmit}>
            <Form.Item hidden name="organization_id">
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="organization_name"
              label={<FormLabel text="Enter organization name" />}
              rules={[{ required: true, message: 'Organization name is required' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="admins" label={<FormLabel text="Organization admins" />} className="mb-2">
              <StyledSelect
                size="large"
                placeholder="Add admins from created accounts"
                showSearch
                optionFilterProp="desc"
                options={adminsOptions}
                mode="multiple"
              />
            </Form.Item>
            <FormItemDescription text="You can leave this field empty and add admins later" />
            <Form.Item name="users" label={<FormLabel text="Organization mapanders" />} className="mb-2">
              <StyledSelect
                size="large"
                placeholder="Add members from created accounts"
                showSearch
                optionFilterProp="desc"
                options={usersOptions}
                mode="multiple"
              />
            </Form.Item>
            <FormItemDescription text="You can leave this field empty and add mapanders later" />
          </Form>
        </Spin>
      </StyledPageContent>
      <FooterButtonContainer>
        <StyledButton disabled={!nameField} style={{ maxWidth: 858 }} className="btn-orange" onClick={() => form.submit()}>
          {!!state ? 'Update Organization' : 'Create Organization'}
        </StyledButton>
      </FooterButtonContainer>
    </Container>
  );
};

export default NewOrganizationPage;
