import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MenuItemLink = styled(Link)<{ isActive: boolean; withShadow?: boolean; disabled?: boolean }>`
  width: auto;
  height: 52px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: ${props => (props.isActive ? '2px solid var(--primary-color)' : 'none')};
  border-radius: 32px;
  background-color: #fff;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
  color: ${props => (props.disabled ? 'var(--bg-basic)' : props.isActive ? 'var(--primary-color)' : 'var(--hint-color)')};
  font-weight: 600;
  box-shadow: ${props => (props.withShadow ? '0 4px 14px 0 rgba(0, 0, 0, 0.07)' : 'none')};

  &:hover {
    color: ${props => (props.disabled ? 'var(--bg-basic)' : 'var(--primary-color)')};
  }

  .anticon {
    transform: translateY(-2px);
  }
  &.show-sm {
    display: none;
  }
  @media (max-width: 1120px) {
    &.hide-sm {
      display: none;
    }

    &.show-sm {
      display: flex;
    }
  }

  @media (max-width: 820px) {
    padding: 0 26px;
  }
`;

export const MenuItemTab = styled.div<{ isActive: boolean; withShadow?: boolean; disabled?: boolean }>`
  width: auto;
  height: 60px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: ${props => (props.isActive ? '2px solid var(--primary-color)' : 'none')};
  border-radius: 32px;
  background-color: #fff;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
  color: ${props => (props.disabled ? 'var(--bg-basic)' : props.isActive ? 'var(--primary-color)' : 'var(--hint-color)')};
  font-weight: 600;
  box-shadow: ${props => (props.withShadow ? '0 4px 14px 0 rgba(0, 0, 0, 0.07)' : 'none')};
  text-align: center;
  &:hover {
    color: ${props => (props.disabled ? 'var(--bg-basic)' : 'var(--primary-color)')};
  }

  .anticon {
    transform: translateY(-2px);
  }
  &.show-sm {
    display: none;
  }
  @media (max-width: 1120px) {
    &.hide-sm {
      display: none;
    }

    &.show-sm {
      display: flex;
    }
  }

  @media (max-width: 820px) {
    padding: 0 26px;
  }
`;
