import React, { useEffect, useState } from 'react';
import { PageContainer, PageWrapper } from '../../assets/styles/Styled';
import { PageContent } from '../LandingPage/styled';
import { Spin, Typography } from 'antd';
import { ChangeVersionForm } from './ChangeVersionForm';
import styled from 'styled-components';
import { useGetCurrentVersion } from '../../hooks/useGetCurrentVersion';
import { ChangeWebAppVersionForm } from './ChangeWebAppForm';
import { IWebConfigN } from '../../types';
import { useRequireAdmin } from '../../hooks/useRequireAdmin';
import { MenuItemTab } from '../../assets/styles/StyledNavigationMenu';
import { ChangeOrganizationForm } from './ChangeOrganizationForm';
import { UploadBuilds } from './UploadBuilds';

const ChangeVersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // gap: 24px;
  background: #ffffff;
  height: 100%;
  width: 100%;
  max-width: 80%;
  padding: 12px 0;
  margin: 0 auto;
`;

export const HeaderText = styled(Typography)`
  margin: 16px 0;
`;

const SettingsPage = styled(PageWrapper)`
  overflow-y: auto;
`;

const SettingsPageContainer = styled(PageContainer)`
  min-height: fit-content;
  // height: fit-content;
`;

const Divider = styled.hr`
  width: 100%;
  border: 2px solid var(--primary-color);
  margin: 36px 0 12px;
`;

const MenuItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-color: #ffffff;
  border-radius: 32px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.07);
  margin: 20px 0;
  &.last {
    margin-top: 0;
  }
`;

type Tab = 'client' | 'web' | 'organizations' | 'builds';

const ChangeVersion = () => {
  const {
    loading,
    currentVersion,
    currentWebConfig,
    currentClientVersionGoIteens,
    loadingWebConfig,
    currentOrganizationId,
    fetchCurrentVersion,
    updateClientVersion,
    fetchWebbAppVersions,
    updateWebAppConfig,
    fetchCurrentOrganization,
    updateOrganization
  } = useGetCurrentVersion();
  useEffect(() => {
    fetchCurrentVersion();
    fetchWebbAppVersions();
    fetchCurrentOrganization();
  }, []);
  const [activeTab, setActiveTab] = useState<Tab>('client');
  const { loaddingPermissions } = useRequireAdmin();

  const renderComponentWithSpin = (component: React.ReactNode) => (
    <Spin
      wrapperClassName="spin-full"
      spinning={loading && loadingWebConfig && loaddingPermissions}
      className="w-100"
      style={{ minWidth: '100% !important', height: '100%' }}
    >
      {component}
    </Spin>
  );
  const isActive = (tabId: Tab) => {
    return tabId === activeTab;
  };
  const handleSetActiveTab = (tabId: Tab) => {
    setActiveTab(tabId);
  };

  return (
    <SettingsPage>
      <SettingsPageContainer>
        <MenuItemsWrapper>
          <MenuItemTab disabled={false} onClick={() => handleSetActiveTab('client')} isActive={isActive('client')}>
            Client Versions
          </MenuItemTab>
          <MenuItemTab disabled={false} onClick={() => handleSetActiveTab('web')} isActive={isActive('web')}>
            Web App Versions
          </MenuItemTab>
        </MenuItemsWrapper>
        <MenuItemsWrapper className="last">
          <MenuItemTab disabled={false} onClick={() => handleSetActiveTab('organizations')} isActive={isActive('organizations')}>
            Default Organization
          </MenuItemTab>
          <MenuItemTab disabled={false} onClick={() => handleSetActiveTab('builds')} isActive={isActive('builds')}>
            Upload builds
          </MenuItemTab>
        </MenuItemsWrapper>

        <PageContent>
          <ChangeVersionWrapper>
            {activeTab === 'client' && (
              <>
                <HeaderText className="bold-24 text-basic">Client Version</HeaderText>
                {renderComponentWithSpin(
                  <ChangeVersionForm
                    loading={loading}
                    configId="client_version"
                    currentVersion={currentVersion}
                    updateVersion={updateClientVersion}
                  />
                )}

                <HeaderText className="bold-24 text-basic">GoITeens Client Version</HeaderText>
                {renderComponentWithSpin(
                  <ChangeVersionForm
                    loading={loading}
                    configId="goiteens_client_version"
                    currentVersion={currentClientVersionGoIteens}
                    updateVersion={updateClientVersion}
                  />
                )}
              </>
            )}

            {activeTab === 'web' && (
              <>
                <HeaderText className="bold-24 text-basic">Web App Config</HeaderText>

                {renderComponentWithSpin(
                  <ChangeWebAppVersionForm
                    configId="builds_download_urls"
                    updateWebAppConfig={updateWebAppConfig}
                    builds_download_urls={currentWebConfig?.builds_download_urls as IWebConfigN}
                  />
                )}

                <HeaderText className="bold-24 text-basic">GoITeens Config</HeaderText>
                {renderComponentWithSpin(
                  <ChangeWebAppVersionForm
                    configId="goiteens_builds_download_urls"
                    updateWebAppConfig={updateWebAppConfig}
                    goiteens_builds_download_urls={currentWebConfig?.goiteens_builds_download_urls as IWebConfigN}
                  />
                )}
              </>
            )}
            {activeTab === 'organizations' && (
              <>
                <HeaderText className="bold-24 text-basic mb-4">Default Organization</HeaderText>
                <ChangeOrganizationForm selectedOrg={currentOrganizationId} updateOrganization={updateOrganization} />
              </>
            )}

            {activeTab === 'builds' && (
              <>
                <HeaderText className="bold-24 text-basic mb-4">Upload builds</HeaderText>
                <UploadBuilds />
              </>
            )}
          </ChangeVersionWrapper>
        </PageContent>
      </SettingsPageContainer>
    </SettingsPage>
  );
};

export default ChangeVersion;
