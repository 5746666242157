import React, { useEffect, useRef, useState } from 'react';
import { IMedia } from '../../types';
import { CloseIcon, PauseIcon, PlayIcon } from '../../assets/icons';
import { formatTime } from '../../utils';
import { Slider } from 'antd';
import styled from 'styled-components';

const ProgressBlock = styled.div`
  width: 100%;

  .ant-slider {
    margin: 0 0 4px 0;
  }
  .ant-slider-rail {
    height: 10px;
    border-radius: 20px;
    background-color: var(--light-violet);
  }
  .ant-slider-track {
    height: 10px;
    border-radius: 20px;
    background-color: var(--primary-color);
  }
  .ant-slider .ant-slider-handle {
    width: 6px;
    height: 15px;

    &:after,
    &:before {
      display: none;
    }
  }
`;

const TimeBlock = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 10px;
  line-height: 14.85px;
  color: var(--hint-color);
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary-color);
  gap: 0 16px;
`;

const StyledPlayIcon = styled(PlayIcon)`
  width: 20px;
  cursor: hover;
`;

const FileName = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17.57px;
`;

const FileWeight = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 12.55px;
`;

const Close = styled.div`
  margin-left: auto;
  > span {
    svg {
      path {
        fill: var(--hint-color);
      }
    }
  }
`;

interface Props {
  audio: IMedia;
  onRemove?: (audioToRemove: string) => void;
  setCurrentTimeCode?: React.Dispatch<React.SetStateAction<number>>;
  isAction?: boolean;
}

const AudioPlayer: React.FC<Props> = ({ audio, onRemove, setCurrentTimeCode, isAction = false }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(audioRef.current?.duration || 0);
      });

      audioRef.current.addEventListener('timeupdate', () => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
          setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
        }
      });

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('timeupdate', () => {});
          audioRef.current.removeEventListener('loadedmetadata', () => {});
        }
      };
    }
  }, []);

  useEffect(() => {
    setCurrentTimeCode && setCurrentTimeCode(currentTime);
  }, [currentTime]);

  const togglePlay = () => {
    if (isAction) return;
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressChange = (value: number) => {
    if (audioRef.current) {
      const newTime = (value / 100) * duration;
      audioRef.current.currentTime = newTime;
      setProgress(value);
      setCurrentTime(newTime);
    }
  };

  const handleClickProgressBar = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newProgress = (clickX / rect.width) * 100;
    handleProgressChange(newProgress);
  };
  return (
    <>
      <Controls>
        {isPlaying ? (
          <PauseIcon className="primary-color cursor-pointer" onClick={togglePlay} />
        ) : (
          <StyledPlayIcon className={`${isAction ? 'hint-color' : 'primary-color cursor-pointer'}`} onClick={togglePlay} />
        )}
        <div>
          <FileName className="fnt-mulish">{audio.media_name}</FileName>
          <FileWeight className="fnt-mulish">3.21kb</FileWeight>
        </div>
        {!isAction && !setCurrentTimeCode && (
          <Close>
            <CloseIcon onClick={() => onRemove && onRemove(audio.id)} />
          </Close>
        )}
      </Controls>
      {(setCurrentTimeCode || isPlaying) && !isAction && (
        <div className="progress">
          <ProgressBlock>
            <div className="progress" onClick={handleClickProgressBar} style={{ cursor: 'pointer' }}>
              <Slider value={progress} onChange={handleProgressChange} tooltip={{ open: false }} />
            </div>
            <TimeBlock>
              {<span>{!!audioRef.current?.currentTime ? formatTime(audioRef.current.currentTime) : '0:00'}</span>}
              <span>{formatTime(duration)}</span>
            </TimeBlock>
          </ProgressBlock>
        </div>
      )}
      <audio ref={audioRef} controls src={audio.media_url} style={{ display: 'none' }} />
    </>
  );
};

export default AudioPlayer;
