import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

export const useRequireAdmin = () => {
  const { allowAccounts } = useAppContext();
  const navigate = useNavigate();
  const [loaddingPermissions, setloaddingPermissions] = useState(true);

  const timeoutRef = useRef<number | null>(null);
  const timeoutLoaddingRef = useRef<number | null>(null);

  useEffect(() => {
    if (!allowAccounts) {
      timeoutRef.current = setTimeout(() => {
        if (!allowAccounts) {
          navigate('/scripts');
        }
      }, 200) as unknown as number;
    }
    timeoutLoaddingRef.current = setTimeout(() => {
      setloaddingPermissions(false);
    }, 200) as unknown as number;
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (timeoutLoaddingRef.current) {
        clearTimeout(timeoutLoaddingRef.current);
      }
    };
  }, [allowAccounts]);
  return { loaddingPermissions };
};
