import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  AccountsResource,
  IActionsConfig,
  IBaseConfigs,
  LiveObjectsIcons,
  LiveObjectWithId,
  OrganizationItem,
  User
} from '../types';
import { useGetAccounts } from '../hooks/useGetAccounts';
import { useAuth } from './AuthContext';
import { useGetOrganizations } from '../hooks/useGetOrganizations';
import { OrganizedLiveObjects, useGetLiveObjects } from '../hooks/useGetLiveObjects';
import { useGetActionsConfig } from '../hooks/useGetActionsConfig';

type GlobalLoading = {
  spin: boolean;
  tip?: string;
};

interface AppContextType {
  defaultLoadingState: GlobalLoading;
  globalLoading: GlobalLoading;
  setGlobalLoading: React.Dispatch<React.SetStateAction<GlobalLoading>>;
  accountsResource: AccountsResource[];
  organizations: OrganizationItem[];
  liveObjects: OrganizedLiveObjects | undefined;
  liveObjectsLoading: boolean;
  liveObjectsBasePath: string;
  accountsLoading: boolean;
  organizationsLoading: boolean;
  fetchAccountResources: () => void;
  fetchOrganizations: () => void;
  setAccountsResource: (data: User[]) => void;
  actionsConfig: IActionsConfig;
  baseActionPaths: IBaseConfigs;
  liveObjectWithId: LiveObjectWithId[];
  liveObjectsIcons: LiveObjectsIcons;
  allowAccounts: boolean;
}

const defaultLoadingState: GlobalLoading = {
  spin: false,
  tip: undefined
};

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FunctionComponent<AppProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const [globalLoading, setGlobalLoading] = useState<GlobalLoading>(defaultLoadingState);
  const { accountsResource, loading: accountsLoading, fetchAccountResources, setAccountsResource } = useGetAccounts();
  const { organizations, loading: organizationsLoading, fetchOrganizations } = useGetOrganizations({ manualFetch: true });
  const [allowAccounts, setAllowAccounts] = useState(false);

  const {
    liveObjects,
    basePath: liveObjectsBasePath,
    fetchLiveObjects,
    loading: liveObjectsLoading,
    liveObjectWithId,
    liveObjectsIcons
  } = useGetLiveObjects({ manualFetch: true });

  const { fetchActionsConfig, fetchBaseConfigs, actionsConfig, baseActionPaths } = useGetActionsConfig({ manualFetch: true });
  const checkIsAllowAccounts = () => {
    const isAdmin = user?.user.role === 'admin';
    const allowAccounts = isAdmin || !!accountsResource?.length;
    setAllowAccounts(allowAccounts);
  };

  useEffect(() => {
    if (user) {
      // fetchAccounts();
      fetchAccountResources();
      fetchOrganizations();
      fetchLiveObjects();
      fetchActionsConfig();
      fetchBaseConfigs();
    }
  }, [user, fetchOrganizations, fetchLiveObjects, fetchActionsConfig, fetchAccountResources]);

  useEffect(() => {
    checkIsAllowAccounts();
  }, [user, accountsResource]);

  return (
    <AppContext.Provider
      value={{
        globalLoading,
        defaultLoadingState,
        accountsResource,
        accountsLoading,
        organizations,
        organizationsLoading,
        liveObjects,
        liveObjectsLoading,
        liveObjectsBasePath,
        actionsConfig,
        baseActionPaths,
        liveObjectWithId,
        liveObjectsIcons,
        allowAccounts,
        setGlobalLoading,
        fetchAccountResources,
        fetchOrganizations,
        setAccountsResource
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};
