import React, { useMemo } from 'react';
import { Button, ButtonProps, Progress, Space, Typography, Upload, UploadProps } from 'antd';
import { UploadIcon } from '../../../../assets/icons';
import styled from 'styled-components';
import { SelectedFile } from './SelectedFile';
import { FileType, ProgressType } from '../../../../types';
import { IPresentationTabs } from '../../sections/PresentationSection/PresentationTabs/usePresentationTabs';
import ProgressDownloadMedia from '../../../../components/ProgressDownloadMedia/ProgressDownloadMedia';

interface UploadButtonProps extends ButtonProps {
  formats?: string;
  FileType?: string;
}

const UploadButton: React.FC<UploadButtonProps> = props => (
  <Button
    type="primary"
    size="large"
    style={{
      borderRadius: 20,
      width: '100%',
      background: props.FileType !== 'audio' ? '#7E42FC' : '#F5FBFC',
      height: props.FileType !== 'audio' ? '40px' : '52px'
    }}
    {...props}
  >
    {props.FileType === 'audio' ? (
      <>
        <Space size={6}>
          <Typography.Text className={`bold-16 primary-color fnt-mulish`}>Add another audio</Typography.Text>
        </Space>
      </>
    ) : (
      <Space size={4}>
        <Typography.Text className={`extrabold-16 text-white`}>Add File</Typography.Text>

        <Typography.Text className="bold-10 text-white">{props.formats || 'Png, Jpg, Pdf'}</Typography.Text>
      </Space>
    )}
  </Button>
);

const Container = styled.div``;
const SelectedFilesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
`;

const StyledUpload = styled(Upload)`
  > div {
    width: 100%;
  }
`;

interface Props {
  disabled: boolean;
  uploadProps: UploadProps;
  isShowUploadBtn: boolean;
  uploadedFiles: FileType[];
  progress: ProgressType;
  uploadConfig: {
    title: string;
    subTitle: string;
    filesFormats: string;
  };
  isHideBtnAdd?: boolean;
  type: IPresentationTabs;
  currentUploadedFile?: FileType | null;

  retryFailedUploads?: () => Promise<void>;
  cancelDownloadFile?: (fileUid: string) => void;
}

export const Uploader: React.FunctionComponent<Props> = ({
  uploadConfig,
  uploadProps,
  progress,
  uploadedFiles,
  isShowUploadBtn,
  disabled,
  isHideBtnAdd = false,
  type,
  currentUploadedFile,
  retryFailedUploads,
  cancelDownloadFile
}) => {
  const isRefreshUpload = useMemo(
    () => progress.status === 'exception' && !!retryFailedUploads,
    [retryFailedUploads, progress.status]
  );
  return (
    <Container>
      <Upload.Dragger {...uploadProps}>
        <Space className="w-100" direction="vertical" size={16}>
          <p className="ant-upload-drag-icon">
            <UploadIcon />
          </p>
          <Space className="w-100" direction="vertical" size={8}>
            <Typography className="bold-16 primary-color">{uploadConfig.title}</Typography>
            <Typography className="bold-12 violet-solid">{uploadConfig.subTitle}</Typography>
          </Space>
          <Space className="w-100" direction="vertical" size={8}>
            <UploadButton formats={uploadConfig.filesFormats} />
            <Typography.Text className="bold-12 violet-solid">Or drag the file here</Typography.Text>
          </Space>
        </Space>
      </Upload.Dragger>
      {(!!uploadedFiles.length || isShowUploadBtn) && (
        <>
          {type === 'audio' && <Typography className="bold-16 description-solid mb-2">Uploaded Files</Typography>}
          <SelectedFilesContainer>
            {!isHideBtnAdd && (
              <StyledUpload {...uploadProps} disabled={disabled}>
                <UploadButton
                  disabled={disabled}
                  FileType={type}
                  formats={type === 'audio' ? 'Add another audio' : uploadConfig.filesFormats}
                />
              </StyledUpload>
            )}

            {uploadedFiles.map(file => (
              <SelectedFile
                key={file.uid}
                completed={!!progress.status}
                file={file}
                onRemove={uid => console.log(uid)}
                type={type}
                isReload={isRefreshUpload}
                currentUploadedFile={currentUploadedFile}
                retryFailedUploads={retryFailedUploads}
                cancelDownloadFile={cancelDownloadFile}
              />
            ))}
            {progress.status && progress.status !== 'normal' && (
              <>
                <ProgressDownloadMedia progress={progress} />
              </>
            )}
          </SelectedFilesContainer>
        </>
      )}
    </Container>
  );
};
