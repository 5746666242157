import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--text-white);
  overflow-y: auto;
`;
const LayoutInner = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const LayoutImage = styled.div`
  background: url('/register-background.svg') no-repeat center top;
  background-size: cover;
  height: 50vh;
  width: 100%;
  border-radius: 24px;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  color: #8987f3;
  background: #f2f6fd;
  width: 100%;
  min-height: 53px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const SignUpLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout>
      <LayoutInner>
        <LayoutImage />

        {children}
      </LayoutInner>

      <Footer>© 2024, made with ♥ by MaPanda</Footer>
    </Layout>
  );
};
export default SignUpLayout;
