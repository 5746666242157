import axios from 'axios';
import {
  Account,
  AuthResponse,
  IActionsConfig,
  IBaseConfigs,
  IChangeCharactersData,
  ICharacters,
  IUploadData,
  IUserEdit,
  IWebConfig,
  LiveObjects,
  Organization,
  OrganizationItem,
  OrganizationUsers,
  Providers,
  RegistrationFormValues,
  ResourceDetail,
  ResourceCreatePayload,
  ScriptDuplicatePayload,
  ScriptFormValues,
  IMoveScriptsData,
  ChangeWebAppVersionsFormValues,
  ResourceResponse,
  AccountsResource,
  User,
  UpdateVersionsConfigId,
  BillingInfoData,
  SignUpFormValues
} from '../types';
import { getStoredAuthData } from '../context/AuthContext';
import { IDeleteResource } from '../types';
import { delay } from '../utils';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'admin'
});

const apiWithoutAdmin = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const appendAuthHeader = () => {
  const authData = getStoredAuthData();
  if (authData && authData.token) {
    api.defaults.headers.common['Authorization'] = authData.token;
  }
};

api.interceptors.response.use(
  response => response, // Simply return for successful responses
  error => {
    console.error('API Error:', error);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
    } else if (!error.response) {
      // Handle network errors or CORS issues where the response is undefined
      console.error('Network error or no CORS headers present');
    }
    return Promise.reject(error);
  }
);

export const authHandler = async (
  email: string,
  password: string,
  ssoToken?: string,
  provider?: Providers
): Promise<AuthResponse> => {
  try {
    const token = btoa(email + '|MP|' + password);
    const postData = {
      provider: provider || 'mapanda',
      token: ssoToken || token
    };
    const response = await api.post<AuthResponse>('/authorization', postData);
    return response.data;
  } catch (error) {
    console.error('Login Error:', error);
    throw error;
  }
};

export const refreshTokenHandler = async (token: string): Promise<AuthResponse> => {
  appendAuthHeader();

  try {
    const response = await api.post<AuthResponse>('/authorization/refresh', { token });
    return response.data;
  } catch (error) {
    console.error('Token refresh error:', error);
    throw error;
  }
};

export const registrationHandler = async (
  data: Pick<RegistrationFormValues, 'email' | 'role' | 'organizations'>
): Promise<User> => {
  appendAuthHeader();
  try {
    const response = await api.post<User>('/users', data);
    return response.data;
  } catch (error) {
    console.error('Registration Error:', error);
    throw error;
  }
};

export const getWebConfig = async () => {
  try {
    const res = await apiWithoutAdmin.get<IWebConfig>('/web-public-config');
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    console.error(e, 'get web config');
  }
};

export const getAccounts = async ({ params }: { params?: string }) => {
  appendAuthHeader();
  try {
    return await api.get<Account>(`/users${params ? params : ''}`);
  } catch (error) {
    console.error('Fetch Accounts Error:', error);
    throw error;
  }
};

export const getAccountsResources = async () => {
  appendAuthHeader();
  try {
    return await api.get<AccountsResource[]>(`/users?load_minimum=true`);
  } catch (error) {
    console.error('Fetch Accounts Error:', error);
    throw error;
  }
};

export const deleteAccount = async (id: string, status: string) => {
  appendAuthHeader();
  try {
    return await api.delete(`/users/${id}`, { data: { status } });
  } catch (error) {
    console.error('Delete Account Error:', error);
    throw error;
  }
};

export const updateAccount = async (id: string, data: IUserEdit) => {
  appendAuthHeader();

  try {
    return await api.patch(`/users/${id}`, data);
  } catch (error) {
    console.error('Update Account Error:', error);
    throw error;
  }
};

export const changeStatus = async (id: string, status: string) => {
  appendAuthHeader();
  try {
    const response = await api.patch(`/users/${id}`, { status });
    return response;
  } catch (error) {
    console.error('Change Status Error:', error);
    throw error;
  }
};

export const changePassword = async (id: string) => {
  appendAuthHeader();
  try {
    const response = await api.post<{ password: string }>(`/users/${id}/regenerate-password`);
    return response.data;
  } catch (error) {
    console.error('Change Password Error:', error);
    throw error;
  }
};

export const getResources = async (queryParams?: string) => {
  appendAuthHeader();
  try {
    const response = await api.get<ResourceResponse>(`/resources/${queryParams || ''}`);
    return response.data;
  } catch (error) {
    console.error('Fetch scripts error:', error);
    throw error;
  }
};

export const getResource = async (resourceId: string, quryParam?: string) => {
  appendAuthHeader();
  try {
    const response = await api.get<ResourceDetail>(`/resources/${resourceId}/${quryParam || ''}`);
    return response.data;
  } catch (error) {
    console.error('Fetch script error:', error);
    throw error;
  }
};

export const moveScripstToFolder = async ({ folderId, scripts, force_parent_access }: IMoveScriptsData) => {
  appendAuthHeader();
  try {
    const data = await api.post(`/resources/move`, {
      move_to_id: folderId,
      resource_ids: scripts,
      ...(force_parent_access ? { force_parent_access: true } : {})
    });
    await delay(1000);
    return data;
  } catch (error) {
    console.error('Move scripts to folder error:', error);
    throw error;
  }
};

export const uploadScript = async (formData: FormData, onProgress: (percent: number) => void) => {
  appendAuthHeader();

  try {
    const data = await api.post<string[]>('/scripts/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        if (progressEvent.total == null) return;
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    });
    await delay(1000);
    return data;
  } catch (error) {
    console.error('Upload Script Error:', error);
    throw error;
  }
};

export const uploadMediaFile = async (fileName: string, options?: { signal?: AbortSignal }) => {
  try {
    const data = await api.get(`/uploads/?filename=${fileName}`, {
      signal: options?.signal
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const uploadMediaFileS3 = async (url: string, formData: FormData, options?: { signal?: AbortSignal }) => {
  try {
    if (!url || !formData) {
      throw new Error('upload failed');
    }
    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      signal: options?.signal
    });
  } catch (e) {
    throw e;
  }
};

export const getLargeFileUploadData = async (): Promise<IUploadData | undefined> => {
  appendAuthHeader();

  try {
    const response = await api.get<IUploadData>('/scripts/upload');
    return response.data;
  } catch (e) {
    console.error('Error getting upload data for large files:', e);
    throw e;
  }
};

export const fetchSlidesForUpload = async (uploadId: string): Promise<ScriptFormValues | undefined> => {
  appendAuthHeader();
  if (!uploadId) return;

  try {
    const response = await api.get<ScriptFormValues>(`uploads/${uploadId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching slides for upload:', error);
    throw error;
  }
};

export const uploadFileToS3 = async (url: string, formData: FormData) => {
  if (!url || !formData) {
    throw new Error('upload failed');
  }
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
};

export const createResource = async (data: ResourceCreatePayload) => {
  appendAuthHeader();
  try {
    const response = await api.post('/resources', data);
    await delay(1000);
    return response;
  } catch (error) {
    console.error('Create Script Error:', error);
    throw error;
  }
};

export const updateResource = async (data: ResourceCreatePayload) => {
  appendAuthHeader();
  const { resource_id, ...restData } = data;
  try {
    const response = await api.patch(`/resources/${resource_id}`, restData);
    await delay(1000);
    return response;
  } catch (error) {
    console.error('Update Script Error:', error);
    throw error;
  }
};

export const duplicateScript = async (data: ScriptDuplicatePayload) => {
  appendAuthHeader();
  try {
    const response = await api.post(`/resources/duplicate`, { ...data });
    await delay(1000);
    return response;
  } catch (error) {
    console.error('Duplicate Script Error:', error);
    throw error;
  }
};

export const deleteResource = async ({ resourceId, deleteChildResources, resourceType }: IDeleteResource) => {
  appendAuthHeader();
  const params =
    deleteChildResources !== undefined && resourceType === 'folder'
      ? { delete_child_resources: deleteChildResources }
      : undefined;
  try {
    const data = await api.delete(`/resources/${resourceId}`, { params });
    await delay(1000);
    return data;
  } catch (error) {
    console.error('Delete Script Error:', error);
    throw error;
  }
};

// Organizations

export const getOrganizations = async () => {
  appendAuthHeader();
  try {
    const response = await api.get<OrganizationItem[]>('/organizations');
    return response.data;
  } catch (error) {
    console.error('Fetch organizations error:', error);
    throw error;
  }
};

export const getOrganizationUsers = async (organizationId: string) => {
  appendAuthHeader();

  try {
    const response = await api.get<OrganizationUsers>(`/organizations/${organizationId}/users`);
    return response.data;
  } catch (error) {
    console.error('Fetch organization users error:', error);
    throw error;
  }
};

export const createOrganization = async (data: Organization) => {
  appendAuthHeader();

  try {
    return await api.post('/organizations', data);
  } catch (error) {
    console.error('Create Organization Error:', error);
    throw error;
  }
};

export const updateOrganization = async (data: Organization) => {
  appendAuthHeader();
  const { organization_id, ...restData } = data;

  try {
    return await api.patch(`/organizations/${organization_id}`, restData);
  } catch (error) {
    console.error('Update Organization Error:', error);
    throw error;
  }
};

export const deleteOrganization = async (organizationId: string) => {
  appendAuthHeader();

  try {
    return await api.delete(`/organizations/${organizationId}`);
  } catch (error) {
    console.error('Delete organization Error:', error);
    throw error;
  }
};

// Live Objects
export const getLiveObjects = async () => {
  appendAuthHeader();

  try {
    const response = await api.get<LiveObjects>(`/live-objects`);
    return response.data;
  } catch (error) {
    console.error('Fetch live objects error:', error);
    throw error;
  }
};

export const getActionsConfig = async () => {
  appendAuthHeader();
  try {
    const { data } = await api.get<IActionsConfig>('/configs/script_actions_config');
    return data;
  } catch (error) {
    console.error('Fetch Actions error:', error);
  }
};

export const getCurrentVersion = async () => {
  appendAuthHeader();

  try {
    const response = await api.get('/configs/client_version');
    return response.data;
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const getClientVersionGoIteens = async () => {
  appendAuthHeader();

  try {
    const response = await api.get('/configs/goiteens_client_version');
    return response.data;
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const updateCurrentVersion = async ({
  configId,
  newConfig
}: {
  configId: UpdateVersionsConfigId;
  newConfig: string | ChangeWebAppVersionsFormValues;
}) => {
  appendAuthHeader();
  const payload =
    typeof newConfig === 'string'
      ? { value: newConfig }
      : {
          value: newConfig[configId as 'builds_download_urls' | 'goiteens_builds_download_urls']
        };
  try {
    await api.put(`/configs/${configId}`, payload);
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const getBaseConfigs = async () => {
  appendAuthHeader();

  try {
    const { data } = await api.get<IBaseConfigs>('/configs/base');
    return data;
  } catch (error) {
    console.error('Fetch Actions error:', error);
  }
};

export const getCharacters = async () => {
  appendAuthHeader();

  try {
    const { data } = await api.get<ICharacters[]>('/characters');
    return data;
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const changeCharactersAvailability = async (id: string, data: IChangeCharactersData) => {
  appendAuthHeader();
  try {
    await api.patch(`/characters/${id}`, data);
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const getOrganizationsApi = async ({ organizationId }: { organizationId: string }) => {
  appendAuthHeader();

  try {
    const { data } = await api.get<{ api_key: string | null }>(`/organizations/${organizationId}/api-key`);
    return data;
  } catch (error) {
    console.error('[ERROR getOrganizationsApi]');
    throw error;
  }
};

export const generateOrganizationsApi = async ({ organizationId }: { organizationId: string }) => {
  appendAuthHeader();

  try {
    const { data } = await api.post<{ api_key: string | null }>(`/organizations/${organizationId}/api-key`);
    return data;
  } catch (error) {
    console.error('[ERROR getOrganizationsApi]');
    throw error;
  }
};

export const removeOrganizationsApi = async ({ organizationId }: { organizationId: string }) => {
  appendAuthHeader();

  try {
    await api.delete(`/organizations/${organizationId}/api-key`);
  } catch (error) {
    console.error('[ERROR getOrganizationsApi]');
    throw error;
  }
};

export const getBillingInfoApi = async ({
  organizationId,
  start_ts,
  end_ts
}: {
  organizationId: string;
  start_ts: number;
  end_ts: number;
}) => {
  appendAuthHeader();

  try {
    const { data } = await api.get<BillingInfoData[]>(`/agora-analytics/${organizationId}?start_ts=${start_ts}&end_ts=${end_ts}`);
    await delay(500);

    return data;
  } catch (error) {
    console.error('[ERROR getOrganizationsApi]');
    throw error;
  }
};

export const uploadFiles = async (formData: FormData): Promise<{ file_url: string }[] | undefined> => {
  appendAuthHeader();
  if (!formData) return;

  try {
    const response = await api.post<{ file_url: string }[]>(`uploads/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching slides for upload:', error);
    throw error;
  }
};

export const signUp = async (signUpData: SignUpFormValues) => {
  try {
    const response = await apiWithoutAdmin.post('/registration', signUpData);
    return response.data;
  } catch (error) {
    console.error('[SIGN_UP]', error);
    throw error;
  }
};

export const signUpConfirm = async (token: string) => {
  try {
    const response = await apiWithoutAdmin.post(`/registration/${token}`);
    return response.data;
  } catch (error) {
    console.error('[SIGN_UP_CONFIRM]', error);
    throw error;
  }
};

export const resendEmail = async (email: string) => {
  try {
    const response = await apiWithoutAdmin.post(`/resend-confirmation`, { email });
    return response.data;
  } catch (error) {
    console.error('[RESEND_EMAIL]', error);
    throw error;
  }
};
export const forgotPassword = async (email: string) => {
  try {
    const response = await apiWithoutAdmin.post(`/forgot-password`, { email });
    return response.data;
  } catch (error) {
    console.error('[FORGOT_PASSWORD]', error);
    throw error;
  }
};
export const resetPassword = async (password: string, token: string) => {
  try {
    const response = await apiWithoutAdmin.post(`/reset-password`, { password, token });
    return response.data;
  } catch (error) {
    console.error('[FORGOT_PASSWORD]', error);
    throw error;
  }
};

export const getDefaultOrganization = async () => {
  appendAuthHeader();

  try {
    const response = await api.get('/configs/default_user_organization');
    return response.data;
  } catch (error) {
    console.error('fetch Current Default Organization error');
    throw error;
  }
};

export const updateDefaultOrganization = async (orgId: string) => {
  appendAuthHeader();

  try {
    await api.put(`/configs/default_user_organization`, { value: orgId });
  } catch (error) {
    console.error('[Update Default Organization error]', error);
    throw error;
  }
};
