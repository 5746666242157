import { useCallback, useMemo } from 'react';
import { NewActionsItem, WhiteboardPreviewItems } from '../../../types';
import { ImageActions } from '../sections/sections.styled';

export const useWhiteboardAction = ({
  slides,
  newActions,
  setNewActions
}: {
  slides: string[];
  newActions: NewActionsItem[];
  setNewActions: React.Dispatch<React.SetStateAction<NewActionsItem[]>>;
}) => {
  const updateBlockWhiteboardActions = (
    prev: { [key: string]: WhiteboardPreviewItems[] },
    blockId: string,
    key: string,
    actionType: string,
    index: number
  ): { [key: string]: WhiteboardPreviewItems[] } => {
    const currentBlockActions: WhiteboardPreviewItems[] = prev[`${blockId}`] || [];
    const actionIndex = currentBlockActions.findIndex(action => action.dropdownKey === `${actionType}-${index}`);
    const updatedActions: WhiteboardPreviewItems[] =
      actionIndex !== -1
        ? currentBlockActions.map((action, idx) =>
            idx === actionIndex
              ? {
                  action: key,
                  blockId: blockId,
                  slideIdx: key === 'WhiteboardShowSlide' ? -1 : index,
                  dropdownKey: `${actionType}-${index}`
                }
              : action
          )
        : [
            ...currentBlockActions,
            {
              action: key,
              blockId: blockId,
              slideIdx: index,
              dropdownKey: `${actionType}-${index}`
            }
          ];
    return {
      ...prev,
      [blockId]: updatedActions
    };
  };

  const handleWhiteboardActionClick = useCallback(
    (uri: string, dropdownKey: string, actionType: string) => {
      setNewActions(prev =>
        prev.map(item => {
          return item.dropdownKey === dropdownKey
            ? {
                ...item,
                uri: uri
              }
            : item;
        })
      );
    },
    [newActions]
  );

  const menuItemsWhiteboardActions = useMemo(() => {
    if (!slides.length) return [];
    return slides.map(item => ({
      key: item,
      label: <ImageActions key={item} src={`${item}`} alt={item} />
    }));
  }, [slides]);

  return {
    menuItemsWhiteboardActions,
    updateBlockWhiteboardActions,
    handleWhiteboardActionClick
  };
};
