import React, { useRef, useState } from 'react';
import { FileType, IActionBlock, IMedia, ScriptFormValues } from '../../types';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { Popconfirm, Typography, Upload, UploadProps } from 'antd';
import { CSS } from '@dnd-kit/utilities';

import styled from 'styled-components';
import { CloseIcon, ExpandIcon } from '../../assets/icons';
import { useVideoPreviewContext } from '../../context/VideoPreviewContext';
import videoIcon from '../../assets/images/video_icon.png';

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 8px;
  overflow-y: hidden;
  border-radius: 8px;
`;

const VideoListContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 150px);
  grid-auto-rows: 110px;
  margin: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, 150px);
  }
`;

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 90px;

  &:hover .closeIcon,
  &:hover .expandIcon {
    visibility: visible;
  }

  &:hover div:after {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  margin-bottom: 4px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 0;
  overflow: hidden;
  border-radius: 12px;
  z-index: 1;

  > img {
    height: 90px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    border-radius: 12px;
    transition: background 0.3s ease;
    z-index: 3;
  }
`;

const StyledUpload = styled(Upload)`
  cursor: pointer;
  > div {
    width: 100%;
  }
`;

const StyledAdd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  height: 90px;
  background-color: var(--light-gray);
  margin-bottom: 4px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  transform: rotate(45deg);
  svg {
    path {
      fill: var(--primary-color);
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 4;
  visibility: hidden;

  span {
    color: #fff;
    font-size: 20px;
    transition: transform 0.2s ease;
  }

  &:hover span {
    transform: scale(1.2);
  }

  &.closeIcon {
    top: 8px;
    right: 8px;
  }

  &.expandIcon {
    right: 8px;
    bottom: 8px;
  }
`;

interface DragableItemProps {
  onRemove: (id: string) => void;
  onPlayVideo: (url: string) => void;
  video: IMedia;
  index: number;
  children: React.ReactNode;
}

const DraggableItem: React.FunctionComponent<DragableItemProps> = ({ video, index, onRemove, children, onPlayVideo }) => {
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({ id: video.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.6 : 1,
    cursor: 'grab'
  };

  return (
    <MainWrapper ref={setNodeRef} style={style} {...attributes}>
      <VideoContainer {...listeners}>{children}</VideoContainer>

      <Typography className="bold-12 light-description ellipsis">{video.media_name || `Video - ${index}`}</Typography>

      <Popconfirm title="Remove this file?" okText="Yes" cancelText="No" onConfirm={() => onRemove(video.id)}>
        <IconContainer className="closeIcon">
          <CloseIcon />
        </IconContainer>
      </Popconfirm>
      <IconContainer className="expandIcon" onClick={() => onPlayVideo(video.media_url)}>
        <ExpandIcon />
      </IconContainer>
    </MainWrapper>
  );
};

interface Props {
  videos: IMedia[];
  uploadProps?: UploadProps;
  setUploadedFiles: React.Dispatch<React.SetStateAction<FileType[]>>;

  onChange: (videos: IMedia[]) => void;
  setFormValues: React.Dispatch<React.SetStateAction<ScriptFormValues>>;
}

const VideoPreviewer: React.FC<Props> = ({ videos, uploadProps, onChange, setFormValues, setUploadedFiles }) => {
  const { setSelectedVideo } = useVideoPreviewContext();
  const [isVertical, setIsVertical] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
        delay: 50
      }
    })
  );

  const filterAndReorderActions = (actions: IActionBlock[], videoToRemove: string) => {
    if (!actions.length) return;
    const filteredBlocks = actions?.filter(action => !(action.action_type === 'media_start' && action.id === videoToRemove));
    const grouped = filteredBlocks.reduce(
      (acc, item) => {
        acc[item.action_type] = acc[item.action_type] || [];
        acc[item.action_type].push(item);
        return acc;
      },
      {} as Record<string, typeof filteredBlocks>
    );

    const indexMap: Record<number, number> = {};
    return Object.values(grouped).flatMap(group =>
      group.map((item, idx) => {
        const prevIndex = Number(item.group);
        if (!(prevIndex in indexMap)) {
          indexMap[prevIndex] = Object.keys(indexMap).length;
        }

        return {
          ...item,
          group: `${indexMap[prevIndex]}`
        };
      })
    );
  };

  const onRemove = (videoToRemove: string) => {
    const removedVideo = videos.find(item => item.id === videoToRemove);

    onChange(videos.filter(v => v.id !== videoToRemove));
    setUploadedFiles(prev => prev.filter(item => item.name !== removedVideo?.media_name));

    setFormValues(prev => {
      return {
        ...prev,
        scenario: prev.scenario.map(scenario => ({
          ...scenario,
          blocks: scenario.blocks.map(block => ({
            ...block,
            actions: filterAndReorderActions(block?.actions || [], videoToRemove) || []
          }))
        }))
      };
    });
  };

  const onPlayVideo = (videoToPlay: string) => {
    setSelectedVideo(videoToPlay);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over, delta } = event;
    if (delta.x === 0 && delta.y === 0) return;

    if (active.id !== over?.id) {
      const oldIndex = videos.findIndex(video => video.id === active.id);
      const newIndex = videos.findIndex(video => video.id === over?.id);
      const newVideos = arrayMove(videos, oldIndex, newIndex);

      onChange(newVideos);
    }
  };

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <Typography className="bold-16 description-solid mb-2">Uploaded Files</Typography>
        <Container>
          <SortableContext items={videos.map(video => ({ id: video.id }))} strategy={rectSortingStrategy}>
            <VideoListContainer>
              {uploadProps && (
                <StyledUpload {...uploadProps} style={{ width: '150px', height: '80px' }}>
                  <StyledAdd>
                    <StyledCloseIcon />
                  </StyledAdd>
                  <Typography className="bold-12 primary-color">Add another video</Typography>
                </StyledUpload>
              )}

              {videos.map((v, idx) => (
                <DraggableItem index={idx} key={v.id} video={v} onRemove={onRemove} onPlayVideo={onPlayVideo}>
                  <img
                    src={v.media_thumbnail || videoIcon}
                    alt={v.media_name}
                    style={v.media_thumbnail ? { width: '100%' } : { width: '50%' }}
                  />
                </DraggableItem>
              ))}
            </VideoListContainer>
          </SortableContext>
        </Container>
      </DndContext>
    </>
  );
};

export default VideoPreviewer;
