import React, { useMemo } from 'react';
import { ProgressType } from '../../types';
import { Progress } from 'antd';

const rootStyle = getComputedStyle(document.documentElement);
const colorPrimary = rootStyle.getPropertyValue('--primary-color').trim();
const colorRed = rootStyle.getPropertyValue('--text-red').trim();
const colorGreen = rootStyle.getPropertyValue('--text-green').trim();
const colorViolet = rootStyle.getPropertyValue('--light-violet').trim();
const colorPink = rootStyle.getPropertyValue('--light-pink').trim();

interface Props {
  progress: ProgressType;
}

const ProgressDownloadMedia: React.FC<Props> = ({ progress }) => {
  const progressBarColor = useMemo(() => {
    if (progress.status === 'active') return colorPrimary;
    if (progress.status === 'success') return colorGreen;
    if (progress.status === 'exception') return colorRed;
    return colorPrimary;
  }, [progress]);
  return (
    <Progress
      size={['100%', 12]}
      trailColor={progress.status === 'exception' ? colorPink : colorViolet}
      strokeColor={progressBarColor}
      status={progress.status}
      percent={progress.percent}
      showInfo={false}
    />
  );
};
export default ProgressDownloadMedia;
