import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import { BasePopUpContent, BasePopUpWrapper, Button } from '../assets/styles/Styled';
import { Slider, Typography } from 'antd';
import { CloseIcon, PauseIcon, PlayIcon } from '../assets/icons';
import { formatTime } from '../utils';

export const StyledPopupHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`;

const PopupContent = styled(BasePopUpContent)`
  max-width: 573px;
  width: 100%;
  height: auto;
  padding: 24px 16px;
`;

const VideoContainier = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 600px;

  &.vertical {
    height: 400px;
    & > video {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  color: var(--primary-color);
  background-color: var(--text-white);
  border-radius: 12px;
  cursor: pointer;

  & > span {
    margin-left: 10px;
  }
`;

const VideoCaontrolBlock = styled.div`
  width: 100%;
  display: flex;
`;

const ProgressBlock = styled.div`
  width: 100%;

  .ant-slider {
    margin: 0 0 4px 8px;
  }
  .ant-slider-rail {
    height: 10px;
    border-radius: 20px;
    background-color: var(--light-violet);
  }
  .ant-slider-track {
    height: 10px;
    border-radius: 20px;
    background-color: var(--primary-color);
  }
  .ant-slider .ant-slider-handle {
    width: 6px;
    height: 15px;

    &:after,
    &:before {
      display: none;
    }
  }
`;

const TimeBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 10px;
  line-height: 14.85px;
  color: var(--light-description);
  padding: 0 5px 0 8px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  & > span {
    width: 24px;
    height: 24px;
  }

  &.icon-play {
    & > span {
      transform: scale(0.8);
    }
  }
`;

const Close = styled.div`
  width: 17px;
  height: 17px;
  margin-top: 10px;
  & span {
    width: inherit;
    height: inherit;

    svg {
      path {
        fill: var(--hint-color);
      }
    }
  }
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 54px;
  font-weight: 800;
  font-size: 16px;
  line-height: 23.76px;
  margin-top: 10px;
`;

interface VideoPreviewType {
  selectedVideo: string | null;

  currentTime: number;
  isMediaAction: boolean;
  savedVideoType: 'with_timecode' | 'close_window' | null;

  setSelectedVideo: React.Dispatch<React.SetStateAction<string | null>>;
  handleCloseVideo: () => void;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMediaAction: React.Dispatch<React.SetStateAction<boolean>>;
  handleClearState: () => void;
}

interface VideoPreviewProviderProps {
  children: ReactNode;
}

const VideoPreviewContext = createContext<VideoPreviewType | undefined>(undefined);

export const VideoPreviewProvider: React.FunctionComponent<VideoPreviewProviderProps> = ({ children }) => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [savedVideoType, setSavedVideoType] = useState<'with_timecode' | 'close_window' | null>(null);
  const [isMediaAction, setIsMediaAction] = useState(false);
  const [isVertical, setIsVertical] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isVideoMounted = useRef<boolean>(false);

  useEffect(() => {
    if (isMediaAction) {
      isVideoMounted.current = true;
    }
  }, [isMediaAction]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleMetadataLoaded = () => {
        setDuration(video.duration);
        const isVerticalVideo = video.videoHeight > video.videoWidth;
        setIsVertical(isVerticalVideo);
      };
      video.addEventListener('timeupdate', updateProgress);
      video.addEventListener('loadedmetadata', handleMetadataLoaded);
    }
    return () => {
      if (video) {
        video.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, [selectedVideo]);

  const updateProgress = useCallback(() => {
    if (videoRef.current) {
      //setCurrentTime(videoRef.current.currentTime);
      setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
    }
  }, []);

  const handlePlayPause = useCallback(() => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
        isVideoMounted.current = true;
      }
    }
  }, [videoRef.current]);

  const handleClose = useCallback(() => {
    setSelectedVideo(null);
    setIsPlaying(false);
    setProgress(0);
    setDuration(0);
    isVideoMounted.current = false;
  }, []);

  const handleClearState = useCallback(() => {
    setSavedVideoType(null);
    setIsMediaAction(false);
    videoRef.current = null;
    handleClose();
  }, [handleClose]);

  const handleSliderChange = useCallback((value: number) => {
    if (videoRef.current) {
      const newTime = (value / 100) * videoRef.current.duration;
      videoRef.current.currentTime = newTime;
      setProgress(value);
    }
  }, []);
  const VideoPopup = (
    <BasePopUpWrapper>
      <PopupContent>
        <StyledPopupHeader>
          <Typography.Text className="primary-text " style={{ fontSize: '16px', fontWeight: '800' }}>
            Preview Video
          </Typography.Text>

          <Close
            className="close"
            onClick={() => {
              setSavedVideoType('close_window');
              handleClose();
            }}
          >
            <CloseIcon />
          </Close>
        </StyledPopupHeader>
        {selectedVideo && (
          <>
            <VideoContainier className={isVertical ? 'vertical' : ''}>
              <video
                ref={videoRef}
                src={selectedVideo}
                controls={false}
                autoPlay={false}
                style={{ width: '100%', borderRadius: 8 }}
                preload="auto"
              />
            </VideoContainier>
            {!isVideoMounted.current && !isMediaAction && selectedVideo && (
              <PlayButton onClick={handlePlayPause}>
                <PlayIcon />
              </PlayButton>
            )}
            {isVideoMounted.current && selectedVideo && (
              <VideoCaontrolBlock>
                <IconWrapper className={!isPlaying ? 'icon-play' : ''} onClick={handlePlayPause}>
                  {isPlaying ? <PauseIcon className="light-description" /> : <PlayIcon className="light-description" />}
                </IconWrapper>

                <ProgressBlock>
                  <Slider value={progress} onChange={handleSliderChange} />
                  <TimeBlock>
                    {<span>{!!videoRef.current?.currentTime ? formatTime(videoRef.current.currentTime) : '0:00'}</span>}
                    <span>{formatTime(duration)}</span>
                  </TimeBlock>
                </ProgressBlock>
              </VideoCaontrolBlock>
            )}
            {isMediaAction && (
              <>
                <StyledBtn onClick={() => setSavedVideoType('with_timecode')} disabled={videoRef.current?.currentTime === 0}>
                  Add timecode
                </StyledBtn>
              </>
            )}
          </>
        )}
      </PopupContent>
    </BasePopUpWrapper>
  );

  const contextValue = useMemo(
    () => ({
      selectedVideo,
      isMediaAction,
      currentTime: videoRef.current?.currentTime || 0,
      savedVideoType,

      setSelectedVideo,
      handleCloseVideo: handleClose,
      setIsPlaying,
      setIsMediaAction,
      handleClearState
    }),
    [selectedVideo, isMediaAction, videoRef.current?.currentTime, savedVideoType, handleClose, handleClearState]
  );

  return (
    <VideoPreviewContext.Provider value={contextValue}>
      {children}
      {selectedVideo && VideoPopup}
    </VideoPreviewContext.Provider>
  );
};

export const useVideoPreviewContext = () => {
  const context = useContext(VideoPreviewContext);
  if (context === undefined) {
    throw new Error('useVideoPreviewContext must be used within a VideoPreviewProvider');
  }
  return context;
};
