import { useCallback, useEffect } from 'react';
import { initialScriptFormValues, useScriptForm } from '../ScriptCreateStore';
import { getResource } from '../../../api/apiWorker';
import { useAuth } from '../../../context/AuthContext';
import { useLocation, useParams } from 'react-router-dom';
import { message } from 'antd';
import { usePreview } from '../../../context/PreviewContext';
import { transformAccessControls, transformLoValues, transformScenarioActions } from '../../../utils';
import { filter } from 'lodash';

export const useGetFormData = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const parentId = params.get('parentId');
  const { setFormValues, setReadOnlyMode, setLoading, setInitialValues, setSharingList, sharingList } = useScriptForm();
  const { setLoInitialFormValues } = usePreview();

  const fetchScript = useCallback(
    async (scriptId: string) => {
      setLoading(true);
      try {
        const resourceData = await getResource(scriptId);
        const { slides, users, scenario, background, access_control, media, ...restData } = resourceData;

        if (users?.some(u => u.user_id === user?.user.user_id)) {
          setReadOnlyMode(false);
        }

        const userItems = access_control?.filter(control => control.entity_type === 'user')?.map(transformAccessControls) ?? [];
        const organizationItems =
          access_control?.filter(control => control.entity_type === 'organization')?.map(transformAccessControls) ?? [];

        const scenarioItems = scenario.map(transformScenarioActions);

        const formValues = {
          ...restData,
          slides,
          video: media?.filter(m => m.media_type === 'video'),
          audio: media?.filter(m => m.media_type === 'audio'),
          organizations: [],
          users: [],
          scenario: scenarioItems,
          background: background || ''
        };
        const initialFormValues = {
          ...formValues,
          organizations: organizationItems,
          users: userItems
        };

        setSharingList({ ...sharingList, users: userItems, organizations: organizationItems });
        setFormValues({ ...initialScriptFormValues, ...formValues });
        setInitialValues({ ...initialScriptFormValues, ...initialFormValues });
        setLoInitialFormValues(transformLoValues(scenarioItems));
      } catch (e) {
        message.error('Unable to fetch script');
        console.error(e, '[GETFORMDATA fetchScript]');
      } finally {
        setLoading(false);
      }
    },
    [user, setFormValues, setLoading, setReadOnlyMode, setInitialValues]
  );

  const fetchParent = async (parentId: string) => {
    const parent = await getResource(parentId);
    const userItems =
      parent.access_control?.filter(control => control.entity_type === 'user')?.map(transformAccessControls) ?? [];
    const organizationItems =
      parent.access_control?.filter(control => control.entity_type === 'organization')?.map(transformAccessControls) ?? [];
    setInitialValues({ ...initialScriptFormValues, organizations: organizationItems, users: userItems });
    setSharingList({ ...sharingList, users: userItems, organizations: organizationItems });
  };

  useEffect(() => {
    id && fetchScript(id);
    parentId && !id && fetchParent(parentId);
  }, [id, parentId, fetchScript]);
};
