import React from 'react';
import { ScriptFormProvider } from './ScriptCreateStore';
import { ScriptForm } from './ScriptForm';
import { PreviewProvider } from '../../context/PreviewContext';
import { VideoPreviewProvider } from '../../context/VideoPreviewContext';
import { AudioPreviewProvider } from '../../context/AudioPreviewContext';

const NewScriptsPage = () => {
  return (
    <PreviewProvider>
      <VideoPreviewProvider>
        <AudioPreviewProvider>
          <ScriptFormProvider>
            <ScriptForm />
          </ScriptFormProvider>
        </AudioPreviewProvider>
      </VideoPreviewProvider>
    </PreviewProvider>
  );
};

export default NewScriptsPage;
