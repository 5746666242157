import React, { FC, useEffect, useMemo, useState } from 'react';
import { updateAccount } from '../api/apiWorker';
import { EditBtn, EditForm, ErrorMsgSm, Label, BasePopUpContent, BasePopUpWrapper } from '../assets/styles/Styled';
import closeIcon from '../assets/close.png';
import { User } from '../types';
import styled from 'styled-components';
import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { emailRegex, regexUserName } from '../utils/regex';
interface PopUpProps {
  accountTo: User;
  setShowPopUpEdit: (value: boolean) => void;
  getAllAccounts: () => void;
}

interface IEditValues {
  email: string;
  firstName: string;
  lastName: string;
}
const PopUpEdit: FC<PopUpProps> = ({ accountTo, setShowPopUpEdit, getAllAccounts }) => {
  const initialValues = useMemo(
    () => ({
      email: accountTo.email || '',
      firstName: accountTo.first_name || '',
      lastName: accountTo.last_name || ''
    }),
    [accountTo.email, accountTo.first_name, accountTo.last_name]
  );
  const [newValues, setNewValues] = React.useState<IEditValues>(initialValues);
  const [errors, setErrors] = useState<IEditValues>({
    email: '',
    firstName: '',
    lastName: ''
  });
  const [requestError, setRequestError] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState(false);

  const FormLabel = styled(Label)`
    font-weight: 800;
    color: #3d6c77;
    margin: 0 0 8px;
  `;

  useEffect(() => {
    const hasErrors = Object.values(errors).some(error => error !== '');
    const hasEmptyFields = Object.values(newValues).some(value => !value);
    const isUnchanged = JSON.stringify(initialValues) === JSON.stringify(newValues);
    if (!hasErrors && !hasEmptyFields && !isUnchanged) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [errors, newValues, initialValues]);

  const validateError = (name: string, value: string) => {
    let error = '';

    switch (name) {
      case 'email':
        if (!value) {
          error = 'Please, enter new email';
        } else if (!emailRegex.test(value)) {
          error = 'Please enter a valid email address';
        }

        break;

      case 'firstName':
        if (!value) {
          error = 'Please, enter First Name';
        } else if (value.length < 2) {
          error = 'First name must be at least 2 characters';
        } else if (value.length > 30) {
          error = 'First name must be no more than 30 characters.';
        } else if (!regexUserName.test(value)) {
          error = "Name must include only letters, ' , - , or . symbols";
        }
        break;

      case 'lastName':
        if (!value) {
          error = 'Please, enter Second Name';
        } else if (value.length < 2) {
          error = 'Second name must be at least 2 characters';
        } else if (value.length > 30) {
          error = 'Second name must be no more than 30 characters.';
        } else if (!regexUserName.test(value)) {
          error = "Name must include only letters, ' , - , or . symbols";
        }
        break;

      default:
        break;
    }

    setErrors(prev => ({ ...prev, [name]: error }));
    return error;
  };
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
    validateError(name, value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsFetching(true);
    e.preventDefault();

    const fieldsToUpdate = {
      login: newValues.email !== accountTo.email ? newValues.email : undefined,
      first_name: newValues.firstName !== accountTo.first_name ? newValues.firstName : undefined,
      last_name: newValues.lastName !== accountTo.last_name ? newValues.lastName : undefined
    };

    const dataToRequest = Object.fromEntries(Object.entries(fieldsToUpdate).filter(([_, value]) => value !== undefined));

    try {
      const res = await updateAccount(accountTo.user_id, dataToRequest);
      if (res) {
        getAllAccounts();

        setShowPopUpEdit(false);
      }
      if (!res) setRequestError('Network response was not ok');
    } catch (error: any) {
      error?.response?.data?.detail
        ? setRequestError(error?.response?.data?.detail)
        : setRequestError('Network response was not ok');
      console.error('error', error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <BasePopUpWrapper>
      <BasePopUpContent>
        <div className="close" onClick={() => setShowPopUpEdit(false)}>
          <img src={closeIcon} alt={''} />
        </div>
        <h2 className="title">Edit Account</h2>

        <EditForm onSubmit={handleSubmit}>
          <div className="edit-form-item">
            <FormLabel htmlFor="firstName">Enter First Name</FormLabel>
            <input
              type="text"
              name="firstName"
              className={errors.firstName ? 'error confirm' : 'confirm'}
              placeholder="Please, enter First Name"
              onChange={onChangeHandler}
              value={newValues.firstName}
            />
            {errors.firstName && <ErrorMsgSm>{errors.firstName}</ErrorMsgSm>}
          </div>
          <div className="edit-form-item">
            <FormLabel htmlFor="lastName">Enter Second Name</FormLabel>
            <input
              type="text"
              name="lastName"
              className={errors.lastName ? 'error confirm' : 'confirm'}
              placeholder="Please, enter Second Name"
              onChange={onChangeHandler}
              value={newValues.lastName}
            />
            {errors.lastName && <ErrorMsgSm>{errors.lastName}</ErrorMsgSm>}
          </div>
          <div className="edit-form-item">
            <FormLabel htmlFor="email">Enter Email</FormLabel>
            <input
              type="email"
              name="email"
              className={errors.email ? 'error confirm' : 'confirm'}
              placeholder="Please, enter email"
              onChange={onChangeHandler}
              value={newValues.email}
              disabled={accountTo.providers ? accountTo.providers.some(provider => provider.provider !== 'mapanda') : false}
            />
            {errors.email && <ErrorMsgSm>{errors.email}</ErrorMsgSm>}
          </div>

          {requestError && <ErrorMsgSm>{requestError}</ErrorMsgSm>}
          <EditBtn type="submit" disabled={isDisabled || isFetching}>
            <Space>
              {' '}
              Save Changes
              <Spin spinning={isFetching} indicator={<LoadingOutlined spin />} size="small" />
            </Space>
          </EditBtn>
        </EditForm>
      </BasePopUpContent>
    </BasePopUpWrapper>
  );
};

export default PopUpEdit;
