import React, { useEffect, useMemo, useState } from 'react';
import { CrownIcon } from '../../../assets/icons';
import { Form, message, Spin } from 'antd';
import { RecoverPasswordFormValues } from '../../../types';
import { SignUpLayout } from '../../../components/SignUpLayout';
import { SignUpButton, SignUpContent, SignUpInput, SignUpLink, SignUpTitle, SignUpWrapper } from '../../../assets/styles/Styled';
import { forgotPassword } from '../../../api/apiWorker';
import { LoadingOutlined } from '@ant-design/icons';

const initialValues: RecoverPasswordFormValues = {
  email: ''
};

const RecoverPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const [form] = Form.useForm<RecoverPasswordFormValues>();
  const formValues = Form.useWatch<RecoverPasswordFormValues>(undefined, form);

  const disableSubmit = useMemo(() => {
    if (!formValues) return true;
    return loading || !formValues?.email || !!emailError?.length;
  }, [loading, formValues, emailError]);
  const handleSubmit = async (values: RecoverPasswordFormValues) => {
    setLoading(true);
    try {
      await forgotPassword(values.email);
      message.success(`We've send you verification email to ${values.email}. Please, confirm your account and come back`);
    } catch (e: any) {
      if (e?.response?.data?.code === 'user_not_found') {
        form.setFields([{ name: 'email', errors: ['Account with this email does not exist'] }]);
        return;
      }
      message.error('Something went wrong, try again later');
      return;
    } finally {
      setLoading(false);
    }
  };
  const handleFieldsChange = (changedFields: any, allFields: any) => {
    const emailField = allFields.find((field: any) => field.name[0] === 'email');
    if (emailField) {
      const error = form.getFieldError('email');
      setEmailError(error.length ? error[0] : null);
    }
  };
  return (
    <SignUpLayout>
      <SignUpWrapper style={{ marginTop: '-140px' }}>
        <SignUpContent>
          <CrownIcon />
          <SignUpTitle>Change Password</SignUpTitle>
          <Form
            name="user-recover-password"
            form={form}
            initialValues={initialValues}
            className="w-100"
            layout="vertical"
            requiredMark={false}
            onFieldsChange={handleFieldsChange}
            // style={{padding}}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="email"
              rules={[{ required: true }, { type: 'email', message: 'Not a valid email' }]}
              validateTrigger="onChange"
            >
              <SignUpInput disabled={loading} type="email" placeholder="Enter Email" />
            </Form.Item>
            <SignUpButton disabled={disableSubmit} type="submit">
              Recover Password <Spin className="light-gray ml-2" indicator={<LoadingOutlined />} spinning={loading} />
            </SignUpButton>
          </Form>
        </SignUpContent>
        <SignUpLink to="/login">Back to Log In</SignUpLink>
      </SignUpWrapper>
    </SignUpLayout>
  );
};

export default RecoverPassword;
