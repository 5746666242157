import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Typography, Popconfirm, Image } from 'antd';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CloseIcon, ExpandIcon } from '../../../assets/icons';
import { ImageEditorModal } from './ImageEditorModal';
import { IActionBlock, ScriptFormValues } from '../../../types';
import { usePreview } from '../../../context/PreviewContext';

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 8px;
  overflow-y: hidden;
  background-color: var(--bg-basic);
  border: 4px solid #fff;
  border-radius: 8px;
`;

const ImageListContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-auto-rows: 110px;
  margin: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, 150px);
  }
`;

const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 90px;

  &:hover .closeIcon,
  &:hover .expandIcon {
    visibility: visible;
  }

  &:hover div:after {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 12px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: rgba(0, 0, 0, 0);
    border-radius: 12px;
    transition: background 0.3s ease;
    z-index: 3;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  border: 3px solid #fff;
  border-radius: 12px;
  z-index: 2;
`;

const StyledPreviewImage = styled(Image)`
  width: 160px !important;
  height: 90px !important;
  object-fit: cover !important;
  border: 3px solid #fff;
  border-radius: 12px;
  z-index: 2;

  @media (max-width: 768px) {
    width: 150px !important;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 4;
  visibility: hidden;

  span {
    color: #fff;
    font-size: 20px;
    transition: transform 0.2s ease;
  }

  &:hover span {
    transform: scale(1.2);
  }

  &.closeIcon {
    top: 8px;
    right: 8px;
  }

  &.expandIcon {
    right: 8px;
    bottom: 8px;
  }
`;

interface DragableItemProps {
  id: string;
  index: number;
  onRemove: (id: string) => void;
  onEdit: (url: string) => void;
  children: ReactNode;
}

const DraggableItem: React.FunctionComponent<DragableItemProps> = ({ id, index, onRemove, onEdit, children }) => {
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.6 : 1,
    cursor: 'grab'
  };

  return (
    <MainWrapper ref={setNodeRef} style={style} {...attributes}>
      <ImageContainer {...listeners}>{children}</ImageContainer>
      <Typography className="bold-12 light-description">{`Slide ${index + 1}`}</Typography>
      <Popconfirm title="Remove this slide?" okText="Yes" cancelText="No" onConfirm={() => onRemove(id)}>
        <IconContainer className="closeIcon">
          <CloseIcon />
        </IconContainer>
      </Popconfirm>
      <IconContainer className="expandIcon">
        <ExpandIcon onClick={() => onEdit(id)} />
      </IconContainer>
    </MainWrapper>
  );
};

interface SliderPreviewProps {
  slides: string[];
  onChange: (slides: string[]) => void;
  readonly: boolean;
  setFormValues: React.Dispatch<React.SetStateAction<ScriptFormValues>>;
}

export const SlidesPreviewBox: React.FC<SliderPreviewProps> = ({ slides, onChange, readonly, setFormValues }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
        delay: 50
      }
    })
  );
  const [editUrl, setEditUrl] = useState<string | undefined>();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over, delta } = event;
    if (delta.x === 0 && delta.y === 0) return;

    if (active.id !== over?.id) {
      const oldIndex = slides.findIndex(slide => slide === active.id);
      const newIndex = slides.findIndex(slide => slide === over?.id);
      const newSlides = arrayMove(slides, oldIndex, newIndex);
      onChange(newSlides);
    }
  };

  const filterAndReorderActions = (actions: IActionBlock[], slideToRemove: string) => {
    if (!actions.length) return;
    const filteredBlocks = actions?.filter(action => !(action.id === 'WhiteboardShowSlide' && action.uri === slideToRemove));

    const grouped = filteredBlocks.reduce(
      (acc, item) => {
        acc[item.action_type] = acc[item.action_type] || [];
        acc[item.action_type].push(item);
        return acc;
      },
      {} as Record<string, typeof filteredBlocks>
    );

    const indexMap: Record<number, number> = {}; // Карта для сохранения старых индексов
    return Object.values(grouped).flatMap(group =>
      group.map((item, idx) => {
        const prevIndex = Number(item.group); // Получаем старый индекс
        if (!(prevIndex in indexMap)) {
          indexMap[prevIndex] = Object.keys(indexMap).length; // Если индекса ещё нет, присваиваем новый по порядку
        }
        return {
          ...item,
          group: `${indexMap[prevIndex]}`
        };
      })
    );
  };

  const onRemove = (slideToRemove: string) => {
    onChange(slides.filter(slide => slide !== slideToRemove));
    setFormValues(prev => {
      return {
        ...prev,
        scenario: prev.scenario.map(scenario => ({
          ...scenario,
          blocks: scenario.blocks.map(block => ({
            ...block,
            actions: filterAndReorderActions(block?.actions || [], slideToRemove) || []
          }))
        }))
      };
    });
  };

  const onImageEdit = (oldUrl: string, newUrl: string) => {
    const updatedSlides = slides.map(slide => (slide === oldUrl ? newUrl : slide));
    onChange(updatedSlides);
    setEditUrl(undefined);
  };

  return (
    <div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <Typography className="bold-16 primary-color mb-2">Preview</Typography>
        <Container>
          {readonly ? (
            <ImageListContainer>
              {slides.map((slide, index) => (
                <div>
                  <StyledPreviewImage src={slide} />
                  <Typography className="bold-12 light-description">{`Slide ${index + 1}`}</Typography>
                </div>
              ))}
            </ImageListContainer>
          ) : (
            <SortableContext items={slides} strategy={rectSortingStrategy}>
              <ImageListContainer>
                {slides.map((slide, index) => (
                  <DraggableItem index={index} key={slide} id={slide} onRemove={onRemove} onEdit={url => setEditUrl(url)}>
                    <StyledImage src={slide} alt={`Slide ${index + 1}`} />
                  </DraggableItem>
                ))}
              </ImageListContainer>
            </SortableContext>
          )}
        </Container>
      </DndContext>
      {editUrl && <ImageEditorModal imageUrl={editUrl} onChange={onImageEdit} onCancel={() => setEditUrl(undefined)} />}
    </div>
  );
};
