import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FooterButtonContainer, PageContent, StyledButton } from '../../assets/styles/Styled';
import { BackLink } from '../../components/BackLink';
import { Spin, TabsProps, Typography } from 'antd';
import { useScriptForm } from './ScriptCreateStore';
import { NameAndShareSection } from './sections/NameAndShareSection';
import { PresentationSection } from './sections/PresentationSection';
import { useSubmit, useGetFormData } from './hooks';
import { StyledTabs } from '../../components/Antd/StyledTabs';
import { LiveObjectsIcon, PersonPlusIcon, PresentationIcon, ScenarioIcon } from '../../assets/icons';
import { useResponsiveTabs } from './hooks';
import { LiveObjectsSection } from './sections/LiveObjectsSection';
import { ScenarioSection } from './sections/ScenarioSection';
import { Sections } from '../../types';
import useModalSharingWarning from '../../hooks/useModalSharingWarning';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  padding-top: 32px;
  width: 100%;
  height: 100%;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`;

const StyledPageContent = styled(PageContent)`
  max-width: 1120px;
  padding: 0;
  flex: 1;

  > div {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
`;

const OverflowY = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const tabs: TabsProps['items'] = [
  {
    label: 'Name & Share',
    icon: <PersonPlusIcon />,
    key: Sections.NameAndShare,
    children: <NameAndShareSection />
  },
  { label: 'Add Materials', icon: <PresentationIcon />, key: Sections.Presentation, children: <PresentationSection /> },
  {
    label: 'Add Live Objects',
    icon: <LiveObjectsIcon />,
    key: Sections.LiveObjects,
    children: (
      <OverflowY>
        <LiveObjectsSection />
      </OverflowY>
    )
  },
  { label: 'Add Scenario', icon: <ScenarioIcon />, key: Sections.Scenario, children: <ScenarioSection /> }
];

export const ScriptForm = () => {
  useGetFormData();
  const { formValues, readOnlyMode, loading, submitDisabled, setCurrentSection, currentSection } = useScriptForm();
  const submitForm = useSubmit();
  const tabItems = useResponsiveTabs(tabs, currentSection);
  const [isShowWarning, setIsShowWarning] = useState(false);
  const { isConfirmForm, setIsVisible } = useModalSharingWarning();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const folderId = params.get('parentId');
  useEffect(() => {
    if (isConfirmForm) {
      handleSubmit(true);
    }
  }, [isConfirmForm]);

  useEffect(() => {
    setIsShowWarning(!!formValues.users.length || !!formValues.organizations.length);
  }, [formValues]);

  const checkIsShowWarning = () => {
    if (isShowWarning) {
      setIsVisible(true);
      return;
    }
  };

  const handleSubmit = async (withoutChecking = false) => {
    if (withoutChecking || !isShowWarning) {
      return await submitForm();
    }
    checkIsShowWarning();
  };
  return (
    <Container>
      <BackLink
        title={formValues.resource_id ? 'Edit Script' : 'Create New Script'}
        path={folderId ? `/scripts?folderId=${folderId}` : '/scripts'}
      />
      {!loading && readOnlyMode && (
        <Typography className="regular-12 text-red">Only administrator or script owner can edit this script</Typography>
      )}
      <StyledPageContent>
        <Spin spinning={loading}>
          <StyledTabs
            size="large"
            centered
            type="card"
            items={tabItems}
            activeKey={currentSection}
            onChange={tab => setCurrentSection(tab as Sections)}
          />
        </Spin>
      </StyledPageContent>
      <FooterButtonContainer>
        <StyledButton
          disabled={readOnlyMode || submitDisabled}
          style={{ maxWidth: 858 }}
          className="btn-orange"
          onClick={() => handleSubmit()}
        >
          {!!formValues.resource_id ? 'Update Script' : 'Create Script'}
        </StyledButton>
      </FooterButtonContainer>
    </Container>
  );
};
