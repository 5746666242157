import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import LoginPage from '../../pages/LoginPage';
import AdminDashboard from '../../pages/AdminDashboard';
import ScriptsPage from '../../pages/ScriptsPage';
import NewScriptsPage from '../../pages/NewScriptsPage';
import UserRegistration from '../../pages/UserRegistration/UserRegistration';
import ProtectedLayout from './ProtectedLayout';
import NotFoundPage from '../../pages/NotFoundPage';
import LandingPage from '../../pages/LandingPage';
import OrganizationsPage from '../../pages/OrganizationsPage';
import NewOrganizationPage from '../../pages/NewOrganizationPage';
import ChangeVersion from '../../pages/ChangeVersion';
import CharactersPage from '../../pages/CharactersPage';
import LandingPageGoIteens from '../../pages/LandingPageGoIteens';
import { GenerateOrganizationApiKeyPage } from '../../pages/GenerateOrganizationApiKey';
import DocsRedirectPage from '../../pages/DocsRedirect/DocsRedirectPage';
import { BillingInfo } from '../../pages/BillingInfo';
import { SignUpConfirm, SignUpPage, SignUpSuccess } from '../../pages/SignUp';
import { ChangePassword, ChangePasswordSuccess, RecoverPassword } from '../../pages/ChangePassword';

const AdminRoutes = () => {
  const router = createBrowserRouter([
    {
      path: '/docs',
      element: <DocsRedirectPage />
    },
    {
      path: '/docs/create-meeting',
      element: <DocsRedirectPage />
    },
    {
      path: '/sign-up',
      element: <SignUpPage />
    },
    {
      path: '/sign-up/success',
      element: <SignUpSuccess />
    },
    {
      path: '/verify',
      element: <SignUpConfirm />
    },
    {
      path: '/recover-password',
      element: <RecoverPassword />
    },
    {
      path: '/reset-password',
      element: <ChangePassword />
    },
    {
      path: '/reset-password/success',
      element: <ChangePasswordSuccess />
    },

    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/download-app',
      element: <LandingPage />
    },
    {
      path: '/download-app/go-iteens',
      element: <LandingPageGoIteens />
    },
    {
      path: '/',
      element: <Navigate to="/admin" replace />
    },
    {
      element: <ProtectedLayout />,
      children: [
        {
          path: '/admin',
          element: <AdminDashboard />
        },
        {
          path: '/user-registration',
          element: <UserRegistration />
        },
        {
          path: '/scripts',
          element: <ScriptsPage />
        },
        {
          path: '/scripts/new',
          element: <NewScriptsPage />
        },
        {
          path: '/scripts/:id',
          element: <NewScriptsPage />
        },
        {
          path: '/organizations',
          element: <OrganizationsPage />
        },
        {
          path: '/organizations/new',
          element: <NewOrganizationPage />
        },
        {
          path: '/organizations/:id',
          element: <NewOrganizationPage />
        },
        {
          path: '/organizations/:id/api_key',
          element: <GenerateOrganizationApiKeyPage />
        },
        {
          path: '/organizations/:id/billing_info',
          element: <BillingInfo />
        },

        {
          path: '/version',
          element: <ChangeVersion />
        },
        {
          path: '/characters',
          element: <CharactersPage />
        }
      ]
    },
    {
      path: '*',
      element: <NotFoundPage />
    }
  ]);

  return <RouterProvider router={router} />;
};

export default AdminRoutes;
