import React, { useEffect, useState } from 'react';
import { Button, PageContainer, PageWrapper, StyledButton } from '../../assets/styles/Styled';
import { useLocation, useParams } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { DatePicker, message, Spin, Typography } from 'antd';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { BillingInfoData, OrganizationItem } from '../../types';
import { BackLink } from '../../components/BackLink';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import BillingTable from './BillingTable';
import { getBillingInfoApi } from '../../api/apiWorker';
dayjs.extend(utc);

const Content = styled.div`
  padding: 2rem 0 2.5rem;
  height: inherit;
  width: inherit;
`;

const MainContent = styled.div`
  padding: 0 1.5rem 0;
  height: inherit;
  width: inherit;
  margin-bottom: -96px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const DownloadButton = styled(Button)`
  width: calc(100% - 3rem);
  height: 46px;
  margin: 15px auto 0;
`;

const ButtonApply = styled(StyledButton)`
  width: fit-content;
  margin-left: 20px;
  height: 32px;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 266px;
  }
`;

const BillingTableWrapper = styled.div`
  height: calc(100vh - 147px - 50px - 86px - 140px);
  overflow-y: auto;
  @media (max-width: 1120px) {
    height: calc(100vh - 147px - 50px - 86px - 210px);
  }
  @media (max-width: 800px) {
    height: calc(100vh - 147px - 50px - 86px - 300px);
  }
`;

const BillingInfo = () => {
  const { state } = useLocation();
  const { organizations, organizationsLoading } = useAppContext();
  const { id } = useParams();
  const [currentOrganization, setCurrentOrganization] = useState<OrganizationItem | null>(null);
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [tableData, setTableData] = useState<BillingInfoData[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (state) {
      return setCurrentOrganization(state);
    }
    const organization = organizations.find(org => org.organization_id === id);
    organization && setCurrentOrganization(organization);
  }, [state, organizations]);

  const fetchBillingInfo = async () => {
    setTableData([]);
    const timestamps = selectedDates.map(date => {
      const startOfDay = dayjs.utc(date).startOf('day').add(1, 'minute'); // 00:01
      const endOfDay = dayjs.utc(date).endOf('day').subtract(1, 'minute'); // 23:59
      return {
        start: Math.floor(startOfDay.valueOf() / 1000),
        end: Math.floor(endOfDay.valueOf() / 1000)
      };
    });
    if (timestamps.length < 2 || !currentOrganization?.organization_id) return;

    try {
      setIsError(false);
      setLoading(true);
      const data = await getBillingInfoApi({
        start_ts: timestamps[0].start,
        end_ts: timestamps[1].end,
        organizationId: currentOrganization.organization_id
      });

      setTableData(data || []);
      setIsFetching(true);
    } catch (error) {
      message.error('Failed to load billing info. Please retry.');
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (date: any, dateString: string[]) => {
    if (!date || date.length < 2) {
      setTableData([]);
      setSelectedDates([]);
      return;
    }
    setSelectedDates(dateString);
  };

  const downloadCSV = () => {
    const formattedData = tableData.map(item => ({
      'Owner email': item.owner_email || null,
      'Peak number of callers': item.call_pcu,
      'Average number of callers': item.call_acu,
      'Meeting start': dayjs(item.created_ts * 1000).format('MM.D.YY h:mm A'),
      'Meeting end': dayjs(item.destroyed_ts * 1000).format('MM.D.YY h:mm A'),
      'Meeting duration': dayjs.duration(item.destroyed_ts - item.created_ts, 'seconds').format('HH:mm:ss')
    }));

    const csv = Papa.unparse(formattedData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${currentOrganization?.organization_name}-meetings.csv`);
  };
  if (currentOrganization && !currentOrganization.is_admin) {
    return (
      <PageWrapper>
        <PageContainer>
          <BackLink title={'Organizations'} path="/organizations" />

          <Typography.Title level={2} className="extrabold-18 hint-color text-align-center" style={{ margin: 'auto 0 auto' }}>
            Only administrators can view this page.
          </Typography.Title>
        </PageContainer>
      </PageWrapper>
    );
  }

  if (!currentOrganization) {
    return (
      <PageWrapper>
        <PageContainer>
          <BackLink title={'Organizations'} path="/organizations" />

          <Typography.Title level={2} className="extrabold-18 hint-color text-align-center" style={{ margin: 'auto 0 auto' }}>
            Organization not found
          </Typography.Title>
        </PageContainer>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageContainer>
        {organizationsLoading ? (
          <Spin spinning={true} style={{ display: 'flex', alignItems: 'center', height: 'inherit' }} />
        ) : (
          <Content>
            <BackLink title={'Organizations'} path="/organizations" />
            <MainContent>
              <Typography.Title
                level={2}
                className="text-title fnt-open-sans text-align-center"
                style={{ fontSize: '24px', fontWeight: '700' }}
              >
                {currentOrganization.organization_name}
              </Typography.Title>
              <DatePickerWrapper>
                <Typography.Text
                  className="text-title fnt-open-sans"
                  style={{ fontSize: '16px', fontWeight: '400', marginRight: '20px' }}
                >
                  Select a date range:
                </Typography.Text>

                <RangePicker
                  dropdownClassName="billing-picker"
                  // onChange={onChange}
                  disabled={loading}
                  disabledDate={current => {
                    return current && (current.isBefore(dayjs().subtract(1, 'year'), 'day') || current.isAfter(dayjs(), 'day'));
                  }}
                  onCalendarChange={(dates, dateString: string[]) => {
                    if (!dates[0] || !dates[1]) {
                      setTableData([]);

                      setSelectedDates([]);
                      return;
                    }
                    onChange(dates, dateString);
                  }}
                />
                <ButtonApply className="btn-green" onClick={fetchBillingInfo} disabled={selectedDates.length < 2}>
                  Apply
                </ButtonApply>
              </DatePickerWrapper>
              <BillingTableWrapper>
                <BillingTable tableData={tableData} notFound={isFetching} loading={loading} isError={isError} />
              </BillingTableWrapper>
            </MainContent>
            {!!tableData.length && (
              <DownloadButton onClick={downloadCSV} disabled={!tableData.length}>
                Download file
              </DownloadButton>
            )}
          </Content>
        )}
      </PageContainer>
    </PageWrapper>
  );
};

export default BillingInfo;
