import { Empty, TableProps } from 'antd';
import React from 'react';
import { StyledTable } from '../../components/Antd/StyledTable';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import styled from 'styled-components';
import { BillingInfoData } from '../../types';

dayjs.extend(duration);

interface BillingTableProps {
  tableData: BillingInfoData[];
  loading: boolean;
  notFound: boolean;
  isError: boolean;
}

const TableWrapper = styled.div`
  .billing-table table {
    background-color: var(--light-gray);
    border-collapse: separate;
    border-spacing: 0 8px;
  }
`;

const BillingTable: React.FC<BillingTableProps> = ({ tableData, loading, notFound, isError }) => {
  const tableColumns: TableProps<BillingInfoData>['columns'] = [
    {
      title: 'Owner Email',
      dataIndex: 'owner_email',
      key: 'owner_email',
      width: '20%',
      ellipsis: true
    },
    {
      title: 'Peak number of callers',
      dataIndex: 'call_pcu',
      key: 'call_pcu',
      width: '10%',
      ellipsis: true,
      align: 'center'
    },
    {
      title: 'Average number of callers',
      dataIndex: 'call_acu',
      key: 'call_acu',
      width: '10%',
      align: 'center',
      ellipsis: true
    },
    {
      title: 'Meeting Start',
      dataIndex: 'created_ts',
      key: 'created_ts',
      width: '20%',
      align: 'center',
      ellipsis: true,
      render: (value, data) => {
        return dayjs(data.created_ts * 1000).format('YYYY-MM-D h:mm:ss A');
      }
    },
    {
      title: 'Meeting End',
      dataIndex: 'destroyed_ts',
      key: 'destroyed_ts',
      width: '20%',
      align: 'center',
      ellipsis: true,
      render: (value, data) => {
        return dayjs(data.destroyed_ts * 1000).format('YYYY-MM-D h:mm:ss A');
      }
    },
    {
      title: 'Meeting duration',
      key: 'duration',
      width: '20%',
      align: 'center',
      render: (value, data) => {
        return dayjs.duration(data.destroyed_ts - data.created_ts, 'seconds').format('HH:mm:ss');
      }
    }
  ];

  const emptyMessage = () => {
    if (isError) {
      return <span className="text-red">Oops! Something went wrong</span>;
    }
    if (notFound) {
      return 'No information found in the selected date period. Try other dates!';
    }
    return 'No data';
  };
  return (
    <TableWrapper>
      <StyledTable
        columns={tableColumns}
        dataSource={tableData}
        rowKey="meeting_id"
        className="billing-table"
        loading={loading}
        pagination={false}
        showSorterTooltip={false}
        scroll={{ x: 300, y: '' }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyMessage()} />
        }}
      />
    </TableWrapper>
  );
};

export default BillingTable;
