import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMedia, NewActionsItem } from '../../../types';
import { useVideoPreviewContext } from '../../../context/VideoPreviewContext';
import { AudioPlayer } from '../../../components/AudioPlayer';
import { useAudioPreviewContext } from '../../../context/AudioPreviewContext';
import { ImageActions } from '../sections/sections.styled';
import videoIcon from '../../../assets/images/video_icon-notes.png';

export const useMediaActions = ({
  media,
  newActions,
  setNewActions
}: {
  media: IMedia[];
  newActions: NewActionsItem[];
  setNewActions: React.Dispatch<React.SetStateAction<NewActionsItem[]>>;
}) => {
  const { setIsMediaAction, setSelectedVideo, currentTime, handleClearState, savedVideoType } = useVideoPreviewContext();
  const { setSelectedAudio, handleClearAudioState, currentTime: currentAudioTime, savedType } = useAudioPreviewContext();
  const [curentMediaId, setCurrentMediaId] = useState<string | null>(null);
  const [currentDropdownKey, setCurrentDropdownKey] = useState<string | null>(null);
  const handleClearCurrentState = () => {
    setCurrentMediaId(null);
    setCurrentDropdownKey(null);
  };

  const handleSetMediaAction = () => {
    setNewActions(
      prev =>
        prev.map(item => {
          return item.dropdownKey === currentDropdownKey
            ? {
                ...item,
                play_timestamp:
                  savedVideoType === 'with_timecode' || savedType === 'with_timecode'
                    ? Math.floor(currentTime || currentAudioTime)
                    : 0
              }
            : item;
        }) as NewActionsItem[]
    );

    handleClearCurrentState();
    handleClearState();
    handleClearAudioState();
  };

  useEffect(() => {
    if (savedVideoType === 'close_window' || savedType === 'close_window') {
      handleClearCurrentState();
      handleClearState();
      handleClearAudioState();
      return;
    }
    if (savedVideoType === 'with_timecode' || savedType === 'with_timecode') {
      handleSetMediaAction();
    }
  }, [savedVideoType, curentMediaId, savedType]);

  const handleMediaActionClick = useCallback(
    (dropdownKey: string, key: string, actionType: string) => {
      const currentAction = newActions.find(na => na.dropdownKey === dropdownKey);
      const curentMedia = media.find(item => item.id === key);

      if (!currentAction?.id || !curentMedia?.id) return;

      setNewActions(
        prev =>
          prev.map(item => {
            return item.dropdownKey === dropdownKey
              ? {
                  ...item,
                  media_id: curentMedia.id,
                  // media_type: currentAction.id,
                  play_timestamp: 0
                }
              : item;
          }) as NewActionsItem[]
      );
    },
    [newActions, media]
  );

  const handleSetTimeCode = ({ dropdownKey }: { dropdownKey: string }) => {
    const currentAction = newActions.find(na => na.dropdownKey === dropdownKey);
    const curentMedia = media.find(item => item.id === currentAction?.media_id);

    if (!currentAction?.media_id || !curentMedia?.id) return;
    setCurrentMediaId(currentAction.media_id);
    setCurrentDropdownKey(dropdownKey);
    //OPEN MEDIA PLAYER
    curentMedia.media_type === 'video' && setIsMediaAction(true);
    curentMedia.media_type === 'audio' ? setSelectedAudio(curentMedia) : setSelectedVideo(curentMedia.media_url);
  };

  const menuVideosActions = useMemo(() => {
    if (!media.length) return [];
    const video = media.filter(item => item.media_type === 'video');
    return video.map(item => ({
      key: item.id,
      label: (
        <ImageActions
          style={{ maxWidth: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}
          key={`${item?.id || 'video'}`}
          src={item?.media_thumbnail || videoIcon}
          alt={item?.media_name || 'video'}
        />
      )
    }));
  }, [media]);
  const menuAudioActions = useMemo(() => {
    if (!media.length) return [];
    const audio = media.filter(item => item.media_type === 'audio');
    return audio.map(item => ({
      key: item.id,
      label: <AudioPlayer audio={item} isAction={true} />
    }));
  }, [media]);

  return {
    menuVideosActions,
    menuAudioActions,
    handleMediaActionClick,
    handleSetTimeCode
  };
};
