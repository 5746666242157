import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Dropdown, MenuProps, Modal, Space, Typography } from 'antd';
import { DropdownIcon } from '../../assets/icons';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { OrganizationItem } from '../../types';
import { useAuth } from '../../context/AuthContext';

const ListItemContainer = styled.div`
  width: 100%;
  padding: 20px 16px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
`;

interface Props {
  organization: OrganizationItem;
  onRemove: (organizationId: string) => void;
}

export const OrganizationListItem: React.FunctionComponent<Props> = ({ organization, onRemove }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const allowActions = user?.user.role === 'admin' || organization.is_admin;

  const handleRemove = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <Typography className="regular-14">
          Please, confirm deleting <b>{organization.organization_name}</b> organization
        </Typography>
      ),
      okText: 'Delete',
      closable: true,
      okButtonProps: { danger: true },
      onOk: () => organization.organization_id && onRemove(organization.organization_id)
    });
  }, [organization, onRemove]);

  const menuItems: MenuProps['items'] = [
    {
      label: <Typography className="normal-16 text-secondary ">Generate Api key</Typography>,
      key: 'generate',
      icon: <EditOutlined className="normal-20 text-secondary hide" />,
      onClick: () => navigate(`/organizations/${organization.organization_id}/api_key`, { state: organization })
    },
    {
      label: <Typography className="normal-16 text-secondary">Billing information</Typography>,
      key: 'billing',
      icon: <EditOutlined className="normal-20 text-secondary hide" />,
      onClick: () => navigate(`/organizations/${organization.organization_id}/billing_info`, { state: organization })
    },
    {
      label: <Typography className="normal-16 text-secondary">Edit Organization</Typography>,
      key: 'edit',
      icon: <EditOutlined className="normal-20 text-secondary" />,
      onClick: () => navigate(`/organizations/${organization.organization_id}`, { state: organization })
    },
    {
      label: <Typography className="normal-16 text-red">Delete Organization</Typography>,
      key: 'delete',
      icon: <DeleteOutlined className="normal-20 text-red" />,
      onClick: () => handleRemove()
    }
  ].filter(menu => (!organization.is_admin ? menu.key !== 'generate' : menu));

  return (
    <ListItemContainer>
      <Space direction="vertical">
        <Typography className="extrabold-14">{organization.organization_name}</Typography>
      </Space>
      {allowActions && (
        <Dropdown trigger={['click']} menu={{ items: menuItems }}>
          <DropdownIcon className="hint-color cursor-pointer" />
        </Dropdown>
      )}
    </ListItemContainer>
  );
};
