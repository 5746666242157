import styled, { css } from 'styled-components';
import { Checkbox, Input, Select, Typography } from 'antd';
import { inputStyles, SharingButton, StyledCustomFormTag, StyledLabel } from '../../../assets/styles/Styled';
import { StyledFieldsWrapper } from '../../LandingPage/styled';
import { CheckIcon, CloseCircleIcon, MagicIcon } from '../../../assets/icons';

export const ScriptSectionWrapper = styled.div`
  margin-top: 12px;
  background: #f5fbfc;
  padding: 16px 8px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const ItemFieldsWrapper = styled(StyledFieldsWrapper)``;

export const StyledSharingButton = styled(SharingButton)``;

export const StyledInput = styled(Input)`
  ${inputStyles}
  &::placeholder {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: #afccd2 !important;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: #afccd2 !important;
    font-size: 16px;
    line-height: 20px;
  }

  .ant-select-selection-search > input {
    color: #062e3a !important;
    font-size: 16px !important;
    padding: 0 16px;
  }
  .ant-select-selector {
    ${inputStyles}
    border-color:  transparent !important;

    &:hover {
      border-color: #9f6bff !important;
    }
  }
  .ant-select-selection-item {
    align-items: center !important;
    height: 44px !important;
  }
  .ant-select-selection-item-remove {
    font-size: 18px !important;
    color: #afccd2 !important;
  }
`;

export const SelectOptionsUser = styled(StyledLabel)``;

export const CustomTag = styled(StyledCustomFormTag)``;

export const SharingList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #7da1a9 !important;
    color: #7da1a9 !important;
    &::after {
      border-color: #7da1a9 !important;
    }
  }
`;

//NOTE STYLES

export const NotesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 70px 16px 16px;
  background-color: var(--light-violet);
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.07);
  min-height: 209px;
  @media (max-width: 450px) {
    padding: 16px 13px 70px 13px;
  }

  .drag-icon-absolute {
    position: absolute;
    top: 16px;
    left: 16px;
    color: var(--primary-color);
  }

  .pen-icon-absolute {
    position: absolute;
    top: 16px;
    right: 42px;
    color: var(--primary-color);
  }
  .delete-icon-absolute {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 22px;
    color: var(--primary-color);
  }
`;

export const PreviewNotesContainer = styled(NotesContainer)`
  padding: 16px 70px 16px 50px;
  @media (max-width: 450px) {
    padding: 48px 16px 16px 16px;
  }
`;

export const StyledCloseCircleIcon = styled(CloseCircleIcon)`
  position: absolute;
  top: 16px;
  right: 26px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: var(--light-gray);
  color: var(--hint-color);
  cursor: pointer;
  @media (max-width: 400px) {
    top: 28px;
    right: 6px;
    height: 32px;
    width: 32px;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  right: 6px;
  bottom: 16px;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  cursor: pointer;
`;

export const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;
export const StyledTextArea = styled(Input.TextArea)`
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 23.76px;
  color: var(--primary-color);
  padding-right: 70px;
  background: var(--light-violet);
  @media (max-width: 400px) {
    padding-right: 40px;
    max-width: 186px;
  }

  &.ant-input,
  &.ant-input:focus,
  &.ant-input:focus-within,
  &.ant-input:hover,
  &.ant-input:active {
    background: var(--light-violet) !important;
  }

  &:hover {
    background-color: var(--light-violet);
  }
`;

export const StyledRightButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 58px;
  margin-left: auto;
  margin-right: -1px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background: #fdedd6;
`;

export const ButtonAddAction = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  width: 238px;
  height: 51px;
  padding: 0 0 0 28px;
  background: #ff8832;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  margin-top: auto;
  @media (max-width: 450px) {
    width: 100%;
  }
  &:disabled {
    background: #c6c1d2;
    color: var(--text-secondary);
    box-shadow: none;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    filter: brightness(110%);
  }

  @media (max-width: 443px) {
    width: 100%;
    max-width: 188px;
    padding: 0 0 0 24px;
  }
`;

export const StyledIcon = styled(MagicIcon)`
  margin-right: 15px;
  @media (max-width: 370px) {
    margin-right: 8px;
  }
`;

export const NotesBlock = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
  background: #fdedd6;
  color: #ff8832;
  border-radius: 16px;
  padding: 8px 48px 8px 16px;
  font-weight: 800;
  width: fit-content;
  @media (max-width: 830px) {
    gap: 16px 10px;
  }
  @media (max-width: 530px) {
    width: 100%;
  }
`;

export const ButtonAction = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  width: fit-content;
  height: 25px;
  padding: 0 8px;
  background: #ff8832;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &:hover {
    filter: brightness(110%);
  }

  & svg {
    margin-right: 4px;
    path {
      fill: #fff;
    }
  }

  &:disabled {
    pointer-events: none;
  }

  @media (max-width: 443px) {
    height: auto;
    padding: 4px 8px;
  }
`;

export const StyledTimeCodeBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  width: fit-content;
  height: 25px;
  padding: 0 8px;
  background: var(--orange-solid);
  border-radius: 8px;
  border: none;
  gap: 0 4px;
`;

export const ImageActions = styled.img`
  width: 80px;
  height: 80px;
`;

export const BlockActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 0;
`;

export const StyledContentPreview = styled(Typography.Paragraph)`
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 23.76px;
`;
