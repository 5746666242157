import React from 'react';
import styled from 'styled-components';
import { IPresentationTabs, usePresentationTabs } from './usePresentationTabs';

const TabsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const Tab = styled.div`
  display: flex;
  padding: 11px 24px;
  border: 1px solid var(--light-description);
  border-radius: 100px;
  width: fit-content;
  color: var(--light-description);
  cursor: pointer;
  &.active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
`;

const TabItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
`;
const TabImage = styled.div`
  display: flex;
  align-items: center;
`;
export const PresentationTabs = ({
  setActiveTab,
  activeTab
}: {
  activeTab: IPresentationTabs;
  setActiveTab: React.Dispatch<React.SetStateAction<IPresentationTabs>>;
}) => {
  const { tabs } = usePresentationTabs();

  const handleChangeTab = (tabId: IPresentationTabs) => {
    setActiveTab(tabId);
  };
  return (
    <TabsContainer>
      {tabs.map(tab => (
        <Tab className={tab.id === activeTab ? 'active' : ''} key={tab.id} onClick={() => handleChangeTab(tab.id)}>
          <TabItem>
            <TabImage>{tab.icon}</TabImage> {tab.title}
          </TabItem>
        </Tab>
      ))}
    </TabsContainer>
  );
};
