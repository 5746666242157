import React, { useMemo, useState } from 'react';
import { Form, Input, message, Select, Space, Typography } from 'antd';
import { RegistrationFormLabel } from '../../components/Antd/FormLabel';
import { StyledButton } from '../../assets/styles/Styled';
import { RoleIcon } from '../../assets/icons';
import { RegistrationFormValues, User } from '../../types';
import { registrationHandler } from '../../api/apiWorker';
import Loader from '../../components/Loader';
import styled from 'styled-components';
import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import { regexUserName } from '../../utils/regex';

const roleOptions = [
  {
    value: 'mapander',
    label: <Typography className="semibold-16 primary-text">MaPander</Typography>,
    title: (
      <Space size={8}>
        <RoleIcon className="regular-16 second-gray" />
        <Typography className="semibold-16 primary-text">MaPander</Typography>
      </Space>
    )
  },
  {
    value: 'admin',
    label: <Typography className="semibold-16 primary-text">Admin</Typography>,
    title: (
      <Space size={8}>
        <RoleIcon className="regular-16 second-gray" />
        <Typography className="semibold-16 primary-text">Admin</Typography>
      </Space>
    )
  }
];

const Container = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
`;

const initialValues: RegistrationFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  confirmEmail: '',
  organizations: [],
  role: 'mapander'
};

interface Props {
  onFinish: (values: User) => void;
}

export const RegistrationForm: React.FunctionComponent<Props> = ({ onFinish }) => {
  const { user } = useAuth();
  const isAdmin = user?.user.role === 'admin';
  const { fetchAccountResources, organizations } = useAppContext();
  const [form] = Form.useForm<RegistrationFormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const formValues = Form.useWatch<RegistrationFormValues>(undefined, form);

  const disableSubmit = useMemo(() => {
    const organizationPass = isAdmin || !!formValues?.organizations?.length;
    return (
      loading ||
      !formValues?.email ||
      !formValues?.confirmEmail ||
      !organizationPass ||
      !formValues.first_name ||
      !formValues.last_name
    );
  }, [loading, formValues, isAdmin]);

  const organizationOptions = useMemo(() => {
    return organizations.map(org => ({
      label: org.organization_name,
      value: org.organization_id
    }));
  }, [organizations]);

  const handleSubmit = async (values: RegistrationFormValues) => {
    setLoading(true);
    const { confirmEmail, ...restValues } = values;

    try {
      const response = await registrationHandler(restValues);
      if (response) {
        fetchAccountResources();
        onFinish(response);
      }
    } catch (e: any) {
      if (e?.message === 'Network Error') {
        message.error('Something went wrong, try again later');
        return;
      }
      form.setFields([{ name: 'email', errors: ['Account with this email already exist.'] }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading && <Loader loading={true} />}
      <Form
        name="user-registration"
        form={form}
        initialValues={initialValues}
        className="w-100"
        layout="vertical"
        requiredMark={false}
        // style={{padding}}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={<RegistrationFormLabel text="First Name" />}
          name="first_name"
          rules={[
            { required: true, message: 'Please, enter First Name' },
            { min: 2, message: 'First name must be at least 2 characters' },
            { max: 30, message: 'First name must be no more than 30 characters.' },
            { type: 'string', message: 'Not a valid first name' },
            { pattern: regexUserName, message: "Name must include only letters, ' , - , or . symbols" }
          ]}
          validateTrigger="onChange"
        >
          <Input type="text" size="large" placeholder="Enter your first name here" />
        </Form.Item>
        <Form.Item
          label={<RegistrationFormLabel text="Second Name" />}
          name="last_name"
          rules={[
            { required: true, message: 'Please, enter Second Name' },
            { min: 2, message: 'Second name must be at least 2 characters' },
            { max: 30, message: 'Second name must be no more than 30 characters.' },
            { type: 'string', message: 'Not a valid second name' },
            { pattern: regexUserName, message: "Name must include only letters, ' , - , or . symbols" }
          ]}
          validateTrigger="onChange"
        >
          <Input type="text" size="large" placeholder="Enter your second name here" />
        </Form.Item>
        <Form.Item
          label={<RegistrationFormLabel text="Email" />}
          name="email"
          rules={[{ required: true }, { type: 'email', message: 'Not a valid email' }]}
          validateTrigger="onChange"
        >
          <Input type="email" size="large" placeholder="Enter your email here" />
        </Form.Item>
        <Form.Item
          label={<RegistrationFormLabel text="Confirm Email" />}
          name="confirmEmail"
          validateTrigger="onChange"
          rules={[
            { required: true, message: 'Please confirm your email!' },
            { type: 'email', message: 'Not a valid email!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Email doesn't match"));
              }
            })
          ]}
        >
          <Input type="email" size="large" placeholder="Re-enter your email here" />
        </Form.Item>
        <Form.Item label={<RegistrationFormLabel text="Role" />} name="role">
          <Select size="large" optionLabelProp="title" options={roleOptions} disabled={!isAdmin} />
        </Form.Item>
        <Form.Item
          label={<RegistrationFormLabel text="Organizations" />}
          name="organizations"
          rules={[{ required: !isAdmin, message: 'Organization is required' }]}
        >
          <Select
            size="large"
            optionLabelProp="label"
            mode="multiple"
            placeholder="Select organizations..."
            options={organizationOptions}
          />
        </Form.Item>
        <StyledButton className="btn-orange W-100 mt-4" type="submit" disabled={disableSubmit}>
          Create Account
        </StyledButton>
      </Form>
    </Container>
  );
};
