import React, { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import { Replica } from './Replica';
import { NoteIcon, ReplicIcon } from '../../../../../assets/icons';
import styled from 'styled-components';
import { StyledButton } from '../../../../../assets/styles/Styled';
import { Scenario, ScenarioBlock } from '../../../../../types';
import { v4 as uuidv4 } from 'uuid';
import { useScriptForm } from '../../../ScriptCreateStore';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { Note } from './Note';
import { useAppContext } from '../../../../../context/AppContext';
import { useNotePreview } from '../../../hooks/useNotePreview';

const ContentContainer = styled.div<{ expanded: boolean }>`
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  opacity: ${props => (props.expanded ? 1 : 0)};
  padding: 16px;
  background-color: var(--light-gray);
  border-radius: 16px;
  transition: opacity 0.2s ease;
`;

const ReplicaButton = styled(StyledButton)`
  width: calc(50% - 3px);
  margin-top: 24px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const NoteButton = styled(StyledButton)`
  width: calc(50% - 3px);
  margin-top: 24px;
  background: var(--violet-solid);
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 12px;
  }
`;

const BlockButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

interface Props {
  scenario: Scenario;
  expanded: boolean;
}

export const ChapterContent: React.FunctionComponent<Props> = ({ expanded, scenario }) => {
  const {
    formValues: { scenario: scenarioState, slides },
    setFormValues
  } = useScriptForm();

  const { actionsConfig, baseActionPaths, liveObjectsBasePath, liveObjectsIcons } = useAppContext();
  const { whiteboardPreviewWithOrder, setEditableBlockId, setWhiteboardPreview, setWhiteboardEditableBlock, setPreviewConfig } =
    useNotePreview();
  const [isShowOnlyWhiteboardOff, setIsShowOnlyWhiteboardOff] = useState(false);

  useEffect(() => {
    const slidesCount = slides.length - 1;
    const lastSlideIdx = whiteboardPreviewWithOrder.length
      ? whiteboardPreviewWithOrder[whiteboardPreviewWithOrder.length - 1].slideIdx
      : -1;
    if (slides.length && lastSlideIdx >= 0 && lastSlideIdx < slidesCount) {
      setIsShowOnlyWhiteboardOff(false);
    } else {
      setIsShowOnlyWhiteboardOff(true);
    }
  }, [whiteboardPreviewWithOrder, slides]);
  const { blocks } = scenario;

  const handleAddReplica = () => {
    const updatedScenarioItems: Scenario[] = scenarioState.map(_scenario => {
      if (_scenario.id === scenario.id) {
        const newBlock: ScenarioBlock = { content: '', type: 'replica', id: uuidv4() };
        const updatedScenario: Scenario = {
          ..._scenario,
          blocks: [..._scenario.blocks, newBlock]
        };
        return updatedScenario;
      } else {
        return _scenario;
      }
    });
    setFormValues(prevState => ({
      ...prevState,
      scenario: updatedScenarioItems
    }));
  };

  const handleAddNote = () => {
    const updatedScenarioItems: Scenario[] = scenarioState.map(_scenario => {
      if (_scenario.id === scenario.id) {
        const newBlock: ScenarioBlock = { content: '', type: 'note', id: uuidv4(), actions: [] };
        const updatedScenario: Scenario = {
          ..._scenario,
          blocks: [..._scenario.blocks, newBlock]
        };
        return updatedScenario;
      } else {
        return _scenario;
      }
    });
    setFormValues(prevState => ({
      ...prevState,
      scenario: updatedScenarioItems
    }));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = blocks.findIndex(block => block.id === active.id);
      const newIndex = blocks.findIndex(block => block.id === over?.id);
      const newBlocks = arrayMove(blocks, oldIndex, newIndex);
      const updatedScenario = scenarioState.map(_scenario => {
        return _scenario.id === scenario.id ? { ..._scenario, blocks: newBlocks } : _scenario;
      });
      setFormValues(prevState => ({
        ...prevState,
        scenario: updatedScenario
      }));
    }
  };
  return (
    <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
      <SortableContext items={blocks.map(block => block.id)} strategy={verticalListSortingStrategy}>
        <ContentContainer expanded={expanded}>
          {!blocks.length && <Typography className="extrabold-16 hint-color text-align-center">Nothing Added</Typography>}
          <Space direction="vertical" size={16} className="w-100">
            {blocks.map((block, idx) => {
              if (block.type === 'replica') {
                return <Replica key={block.id} scenarioId={scenario.id} block={block} />;
              } else if (block.type === 'note') {
                return (
                  <Note
                    key={block.id}
                    scenarioId={scenario.id}
                    block={block}
                    actionsConfig={actionsConfig}
                    baseActionPaths={baseActionPaths}
                    isShowOnlyWhiteboardOff={isShowOnlyWhiteboardOff}
                    liveObjectsBasePath={liveObjectsBasePath}
                    liveObjectsIcons={liveObjectsIcons}
                    setEditableBlockId={setEditableBlockId}
                    setWhiteboardPreview={setWhiteboardPreview}
                    setWhiteboardEditableBlock={setWhiteboardEditableBlock}
                    setPreviewConfig={setPreviewConfig}
                  />
                );
              }
              return null;
            })}
          </Space>
          <BlockButtonsWrapper>
            <ReplicaButton className="btn-blue" onClick={handleAddReplica}>
              <Space>
                <ReplicIcon className="text-white" />
                <Typography className="extrabold-16 text-white">Add Replica</Typography>
              </Space>
            </ReplicaButton>
            <NoteButton onClick={handleAddNote}>
              <Space>
                <NoteIcon />
                <Typography className="extrabold-16 text-white">Add Supporting Note</Typography>
              </Space>
            </NoteButton>
          </BlockButtonsWrapper>
        </ContentContainer>
      </SortableContext>
    </DndContext>
  );
};
