import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signUpConfirm } from '../../../api/apiWorker';
import { SignUpLayout } from '../../../components/SignUpLayout';
import { Spin } from 'antd';

const SignUpConfirm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleConfirm = async (confirmationCode: string) => {
    try {
      await signUpConfirm(confirmationCode);
      navigate('/login', { state: { account_created: 'success' } });
    } catch (e) {
      navigate('/sign-up/success', { state: { account_created: 'faild' } });

      console.error('[SignUpConfirm]', e);
    }
  };

  useEffect(() => {
    const confirmationCode = searchParams.get('token');
    if (confirmationCode) {
      handleConfirm(confirmationCode);
    }
  }, [searchParams]);
  return (
    // <SignUpLayout>
    <Spin spinning className="w-100" size="large" />
    // </SignUpLayout>
  );
};

export default SignUpConfirm;
