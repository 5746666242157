import { Button, ButtonProps, Space, Spin, Typography, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { CopyIcon, UploadIcon } from '../../assets/icons';
import styled from 'styled-components';
import { useUploadMedia } from '../NewScriptsPage/hooks';
import { IMedia } from '../../types';
import ProgressDownloadMedia from '../../components/ProgressDownloadMedia/ProgressDownloadMedia';

interface UploadButtonProps extends ButtonProps {
  disabled: boolean;
  FileType?: string;
}

const Container = styled.div`
  width: 90%;
  .upload-container {
    > div {
      padding: 16px 60px !important;
    }
  }

  @media (max-width: 768px) {
    .upload-container {
      > div {
        padding: 16px 16px !important;
      }
    }
  }
`;

const FieldMediaUrl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid var(--hint-color);
  padding: 0 14px;
  border-radius: 40px;
  height: 50px;
  margin-top: 1rem;
`;

const FileName = styled.div`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  width: 30px;
  color: var(--hint-color);
  border-radius: 0;
  height: 100%;
  border-left: 1px solid var(--hint-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  cursor: pointer;
  & span {
    width: inherit;
    & > svg {
      rect {
        fill: var(--hint-color);
      }
    }
  }
`;

const UploadButton: React.FC<UploadButtonProps> = props => (
  <Button
    type="primary"
    size="large"
    style={{
      borderRadius: 20,
      width: '100%',
      background: '#7E42FC',
      height: '40px'
    }}
    {...props}
  >
    <Space size={4}>
      <Typography.Text className={`extrabold-16 text-white`}>Add File</Typography.Text>
    </Space>
  </Button>
);

export const UploadBuilds = () => {
  const [isCopied, setIsCopied] = useState(false);

  const [file, setFile] = useState<IMedia | null>(null);

  const { progressMedia, uploadPropsMedia, currentUploadedFile, setSelectedFiles } = useUploadMedia({
    onChange: (media: IMedia) => setFile(media),
    type: 'builds',
    isShowUploader: false,
    multipleLoader: false
  });
  useEffect(() => {
    if (progressMedia.status === 'exception') {
      setSelectedFiles([]);
    }
  }, [progressMedia.status]);

  const copyToClipboard = async () => {
    setIsCopied(true);
    try {
      if (file?.media_url) {
        await navigator.clipboard.writeText(file?.media_url);
      }
    } catch (err) {
      console.error('Could not copy text: ', err);
    } finally {
      setTimeout(() => {
        setIsCopied(false);
      }, 300);
    }
  };

  const uploadProps = {
    ...uploadPropsMedia,
    style: {
      padding: '0'
    },
    className: 'upload-container'
  };

  return (
    <Container>
      <div className="ee">
        <Upload.Dragger disabled={progressMedia.status !== 'exception' && !!currentUploadedFile} {...uploadProps}>
          <Space className="w-100" direction="vertical" size={16}>
            <p className="ant-upload-drag-icon">
              <UploadIcon />
            </p>
            <Space className="w-100" direction="vertical" size={8}>
              <Typography className="bold-16 primary-color">Upload Build</Typography>
            </Space>
            <Space className="w-100" direction="vertical" size={8}>
              <UploadButton disabled={progressMedia.status !== 'exception' && !!currentUploadedFile} />
              <Typography.Text className="bold-12 violet-solid">Or drag the file here</Typography.Text>
            </Space>
          </Space>
        </Upload.Dragger>
      </div>

      {progressMedia.status && progressMedia.status !== 'normal' && (
        <>
          <ProgressDownloadMedia progress={progressMedia} />
        </>
      )}
      {file?.media_url && progressMedia.status === 'normal' && (
        <FieldMediaUrl className="fnt-mulish">
          <FileName>{file.media_url}</FileName>
          <IconWrapper onClick={copyToClipboard}>{isCopied ? <Spin spinning className="spin-hint" /> : <CopyIcon />}</IconWrapper>
        </FieldMediaUrl>
      )}
    </Container>
  );
};
