import { CrownIcon } from '../../../assets/icons';
import { ChangePasswordFormBalues } from '../../../types';
import { Form, message, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { SignUpButton, SignUpContent, SignUpInput, SignUpLink, SignUpTitle, SignUpWrapper } from '../../../assets/styles/Styled';
import { SignUpLayout } from '../../../components/SignUpLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../../api/apiWorker';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const initialValues: ChangePasswordFormBalues = {
  password: '',
  confirm_password: ''
};

const CustomWrapper = styled(SignUpWrapper)`
  margin-top: -232px;
  @media (max-width: 1280px) {
    margin-top: -190px;
  }
`;

const ChangePassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmToken, setConfirmToken] = useState('');
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const confirmationCode = searchParams.get('token');
    if (confirmationCode) {
      setConfirmToken(confirmationCode);
    }
  }, [searchParams]);

  const [form] = Form.useForm<ChangePasswordFormBalues>();
  const formValues = Form.useWatch<ChangePasswordFormBalues>(undefined, form);
  const disableSubmit = useMemo(() => {
    if (!formValues) return true;
    return loading || !formValues.password || !formValues?.confirm_password;
  }, [loading, formValues]);

  const handleSubmit = async (values: ChangePasswordFormBalues) => {
    if (!values.password) return;
    if (!confirmToken) {
      message.error(
        'The password reset URL is invalid or missing required information. Please request a new password reset link.'
      );
      return;
    }
    setLoading(true);

    try {
      await resetPassword(values.password, confirmToken);
      navigate('/reset-password/success');
    } catch (e) {
      message.error('Something went wrong, try again later');
    }
  };
  return (
    <SignUpLayout>
      <CustomWrapper>
        <SignUpContent>
          <CrownIcon />
          <SignUpTitle>Change Password</SignUpTitle>
          <Form
            name="user-change-password"
            form={form}
            initialValues={initialValues}
            className="w-100"
            layout="vertical"
            requiredMark={false}
            disabled={loading}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
                { min: 8, message: 'Password must be at least 8 characters' }
              ]}
              validateTrigger="onSubmit"
            >
              <SignUpInput disabled={loading} type="password" placeholder="Enter Password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match'));
                  }
                })
              ]}
              validateTrigger="onSubmit"
            >
              <SignUpInput disabled={loading} type="password" placeholder="Re-enter new Password" />
            </Form.Item>
            <SignUpButton disabled={disableSubmit} type="submit">
              Change Password <Spin className="light-gray ml-2" indicator={<LoadingOutlined />} spinning={loading} />
            </SignUpButton>
          </Form>
        </SignUpContent>

        <SignUpLink to="/login">Back to Log In</SignUpLink>
      </CustomWrapper>
    </SignUpLayout>
  );
};

export default ChangePassword;
