import React from 'react';
import { ActionButtonEnum, ActionEnum, IActionsType, IMedia, NewActionsItem } from '../../../../../types';
import { Dropdown, MenuProps } from 'antd';
import styled from 'styled-components';
import { CloseCircleIcon } from '../../../../../assets/icons';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ButtonAction, NotesBlock, StyledTimeCodeBlock } from '../../sections.styled';
import { formatTime } from '../../../../../utils';

interface ISortableActionItems {
  blockId: string;
  idx: number;
  dropdownKey: string;
  item: string;
  openDropdowns: {
    [key: string]: boolean;
  };
  newActions: NewActionsItem[];
  liveObjects: string[];
  uid: string;
  slides: string[];
  media: IMedia[];
  renderMenuActionsItems: (action_id: IActionsType, idx: number) => MenuProps | undefined;
  handleOpenDropdown: (dropdownKey: string, flag: boolean) => void;
  setSelectedActionsItem: React.Dispatch<React.SetStateAction<string[]>>;
  renderIcon: (actionType: IActionsType, dropdownKey: string) => JSX.Element;
  renderIconAwardsEffects: (dropdownKey: string) => JSX.Element;
  renderTitle: (actionType: string, dropdownKey: string) => JSX.Element | ActionButtonEnum | undefined;
  renderTitleAwardsEffects: (dropdownKey: string) => JSX.Element;
  renderLoMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderWhiteboardMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderMediaMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderIconItemsLo: (actionType: IActionsType, dropdownKey: string) => JSX.Element | undefined;
  renderTitleLo: (dropdownKey: string) => string | undefined;
  handleRemoveAction: (selectedActionIdx: number) => void;
  renderTitleWhiteboard: (dropdownKey: string) => string | JSX.Element;
  renderTitleMedia: (dropdownKey: string) => string | JSX.Element;
  handleSetTimeCode: ({ dropdownKey }: { dropdownKey: string }) => void;
}

const ArrowDownIcon = styled.div<{ open: boolean }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin-left: 11px;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
`;
const StyledCloseIcon = styled(CloseCircleIcon)`
  position: absolute;
  top: 8px;
  right: 16px;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #ffe1b7;
  color: #f9b192;
  cursor: pointer;
  > svg {
    > rect {
      fill: #ffe1b7;
    }
  }
`;

const menuItemsDirectionColumns = ['drawing', 'whiteboard', 'live_object', 'media'];

export const SortableActionItems: React.FC<ISortableActionItems> = React.memo(
  ({
    idx,
    dropdownKey,
    item,
    blockId,
    openDropdowns,
    newActions,
    liveObjects,
    uid,
    slides,
    media,
    renderMenuActionsItems,
    handleOpenDropdown,
    setSelectedActionsItem,
    renderIcon,
    renderIconAwardsEffects,
    renderTitle,
    renderTitleAwardsEffects,
    renderLoMenuActionsItems,
    renderMediaMenuActionsItems,
    renderWhiteboardMenuActionsItems,
    renderIconItemsLo,
    renderTitleLo,
    handleRemoveAction,
    renderTitleWhiteboard,
    renderTitleMedia,
    handleSetTimeCode
  }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: uid
    });
    const draggableStyle = {
      transform: `${CSS.Translate.toString(transform)} rotate(${isDragging ? '-3deg' : '0deg'})`,

      transition,
      zIndex: isDragging ? 1000 : 'auto',
      opacity: isDragging ? 0.5 : 1
    };

    const renderDropdown = (
      dropdownKey: string,
      item: string,
      idx: number,
      menuRenderer: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined,
      titleRenderer: (dropdownKey: string) => JSX.Element | string | undefined
    ) => {
      return (
        <Dropdown
          key={`${dropdownKey}-drop`}
          menu={menuRenderer(dropdownKey, idx, item)}
          placement="top"
          overlayClassName={`overly-menu-note-items overly-menu-note-items-${item}-items`}
          className={`drop-menu-${item}`}
          trigger={['click']}
          open={!!openDropdowns[`${dropdownKey}-drop`]}
          onOpenChange={flag => handleOpenDropdown(`${dropdownKey}-drop`, flag)}
        >
          <ButtonAction
            key={`btn-menu-item-${dropdownKey}`}
            onClick={() => {
              const currentAction =
                newActions.find(action => action.dropdownKey === dropdownKey)?.live_object_id ||
                newActions.find(action => action.dropdownKey === dropdownKey)?.media_id ||
                newActions.find(action => action.dropdownKey === dropdownKey)?.id ||
                '';
              setSelectedActionsItem(prev => [...prev, currentAction]);
            }}
          >
            {titleRenderer(dropdownKey)}
            <ArrowDownIcon open={!!openDropdowns[`${dropdownKey}-drop`]} />
          </ButtonAction>
        </Dropdown>
      );
    };

    return (
      <NotesBlock key={dropdownKey + blockId} ref={setNodeRef} style={{ ...draggableStyle, minHeight: 'unset' }} {...attributes}>
        <div style={{ cursor: 'ns-resize' }} {...listeners}>
          {ActionEnum[item as keyof typeof ActionEnum]}
        </div>
        <Dropdown
          key={dropdownKey}
          menu={renderMenuActionsItems(item as IActionsType, idx)}
          placement="top"
          overlayClassName={`overly-menu-note-items ${'overly-menu-note-items-' + item} ${menuItemsDirectionColumns.includes(item) ? 'overly-menu-note-items-columns' : ''}`}
          className="drop-menu"
          trigger={['click']}
          open={!!openDropdowns[dropdownKey]}
          onOpenChange={flag => handleOpenDropdown(dropdownKey, flag)}
        >
          <ButtonAction
            key={'btn-menu'}
            onClick={() => {
              let effectsAction = '';
              const currentAction = newActions?.find(item => item.dropdownKey === dropdownKey)?.id || '';
              if (item === 'awards') {
                effectsAction = newActions.find(item => item.dropdownKey === dropdownKey.replace('awards', 'effects'))?.id || '';
              }
              setSelectedActionsItem(prev => [...prev, currentAction, effectsAction]);
            }}
          >
            {/* Name of the first dropdown in the list  */}
            {item !== 'awards' && renderIcon(item as IActionsType, dropdownKey)}
            {item === 'awards' && renderIconAwardsEffects(dropdownKey)}
            {item !== 'awards' && renderTitle(item, dropdownKey)}
            {item === 'awards' && renderTitleAwardsEffects(dropdownKey)}
            <ArrowDownIcon open={!!openDropdowns[dropdownKey]} />
          </ButtonAction>
        </Dropdown>
        {/* SECOND DROPDOWN */}
        {!!liveObjects.length &&
          !!newActions.length &&
          newActions.find(action => action.dropdownKey === dropdownKey)?.id === 'place_live_objects' &&
          renderDropdown(dropdownKey, 'live_object', idx, renderLoMenuActionsItems, key => (
            <>
              {renderIconItemsLo('place_live_objects' as IActionsType, key)}
              {renderTitleLo(key) || ''}
            </>
          ))}

        {!!slides.length &&
          !!newActions.length &&
          newActions.find(action => action.dropdownKey === dropdownKey)?.id === 'WhiteboardShowSlide' &&
          renderDropdown(dropdownKey, 'whiteboard', idx, renderWhiteboardMenuActionsItems, renderTitleWhiteboard)}

        {!!media.length &&
          !!newActions.length &&
          newActions.find(action => action.dropdownKey === dropdownKey)?.id === 'media_start' &&
          renderDropdown(dropdownKey, 'media', idx, renderMediaMenuActionsItems, renderTitleMedia)}

        {!!newActions.find(action => action.dropdownKey === dropdownKey)?.media_id && (
          <StyledTimeCodeBlock
            onClick={() =>
              handleSetTimeCode({
                dropdownKey: dropdownKey
              })
            }
          >
            <span>Timecode</span>
            <span className="timecode-time cursor-pointer">
              {formatTime(Number(newActions.find(action => action.dropdownKey === dropdownKey)?.play_timestamp || '0:00'))}
            </span>
          </StyledTimeCodeBlock>
        )}
        <StyledCloseIcon onClick={() => handleRemoveAction(idx)} />
      </NotesBlock>
    );
  }
);
