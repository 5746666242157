import { useMemo, useState } from 'react';
import { SignUpFormValues } from '../../../types';
import { Form, message, Spin } from 'antd';
import styled from 'styled-components';
import { regexUserName } from '../../../utils/regex';
import { useNavigate } from 'react-router-dom';
import { SignUpButton, SignUpInput } from '../../../assets/styles/Styled';
import { signUp } from '../../../api/apiWorker';
import { LoadingOutlined } from '@ant-design/icons';
const initialValues: SignUpFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: ''
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  color: var(--text-dusty-blue);

  .ant-form-item {
    margin-bottom: 16px;
    @media (max-width: 768px) {
      margin-bottom: 18px;
    }
  }

  .ant-form-item-explain-error {
    color: #ef5a55;
    font-size: 12px;
  }
`;

export const SignUpForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm<SignUpFormValues>();
  const formValues = Form.useWatch<SignUpFormValues>(undefined, form);
  const disableSubmit = useMemo(() => {
    if (!formValues) return true;
    return (
      loading ||
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues?.email ||
      !formValues.password ||
      !formValues?.confirm_password
    );
  }, [loading, formValues]);

  const handleSubmit = async (values: SignUpFormValues) => {
    setLoading(true);
    localStorage.setItem('signUpEmail', values.email);
    const { confirm_password, ...restValues } = values;

    try {
      await signUp(restValues);
      navigate('/sign-up/success');
    } catch (e: any) {
      if (e?.response?.data?.code === 'user_already_exists') {
        form.setFields([{ name: 'email', errors: ['Account with this email already exist.'] }]);
        return;
      }
      message.error('Something went wrong, try again later');
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <Form
        name="user-sign-up"
        form={form}
        initialValues={initialValues}
        className="w-100"
        layout="vertical"
        requiredMark={false}
        // style={{padding}}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="first_name"
          rules={[
            { required: true, message: 'This field cannot be empty' },
            { min: 2, message: 'First name must be at least 2 characters' },
            { max: 30, message: 'First name must be no more than 30 characters.' },
            { type: 'string', message: 'Not a valid first name' },
            { pattern: regexUserName, message: "Name must include only letters, ' , - , or . symbols" }
          ]}
          validateTrigger="onChange"
        >
          <SignUpInput disabled={loading} type="text" placeholder="Enter First name" />
        </Form.Item>

        <Form.Item
          name="last_name"
          rules={[
            { required: true, message: 'This field cannot be empty' },
            { min: 2, message: 'Last name must be at least 2 characters' },
            { max: 30, message: 'Last name must be no more than 30 characters.' },
            { type: 'string', message: 'Not a valid Last name' },
            { pattern: regexUserName, message: "Last Name must include only letters, ' , - , or . symbols" }
          ]}
          validateTrigger="onChange"
        >
          <SignUpInput disabled={loading} type="text" placeholder="Enter Last name" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true }, { type: 'email', message: 'Not a valid email' }]}
          validateTrigger="onChange"
        >
          <SignUpInput disabled={loading} type="email" placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please enter your password' },
            { min: 8, message: 'Password must be at least 8 characters' }
          ]}
          validateTrigger="onSubmit"
        >
          <SignUpInput disabled={loading} type="password" placeholder="Enter Password" />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          rules={[
            { required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              }
            })
          ]}
          validateTrigger="onSubmit"
        >
          <SignUpInput disabled={loading} type="password" placeholder="Re-enter Password" />
        </Form.Item>
        <SignUpButton disabled={disableSubmit} type="submit">
          Get Access <Spin className="light-gray ml-2" indicator={<LoadingOutlined />} spinning={loading} />
        </SignUpButton>
      </Form>
    </FormContainer>
  );
};
