import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import { BasePopUpContent, BasePopUpWrapper, Button } from '../assets/styles/Styled';
import { Typography } from 'antd';
import { CloseIcon } from '../assets/icons';
import { IMedia } from '../types';
import { AudioPlayer } from '../components/AudioPlayer';

export const StyledPopupHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`;

const PopupContent = styled(BasePopUpContent)`
  max-width: 573px;
  width: 100%;
  height: auto;
  padding: 24px 16px;
`;

const PlayerContainer = styled.div`
  width: 100%;
`;

const Close = styled.div`
  width: 17px;
  height: 17px;
  margin-top: 10px;
  & span {
    width: inherit;
    height: inherit;

    svg {
      path {
        fill: var(--hint-color);
      }
    }
  }
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 54px;
  font-weight: 800;
  font-size: 16px;
  line-height: 23.76px;
  margin-top: 10px;
`;

interface VideoPreviewType {
  savedType: 'with_timecode' | 'close_window' | null;
  currentTime: number;
  setSelectedAudio: React.Dispatch<React.SetStateAction<IMedia | null>>;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  handleClearAudioState: () => void;
}

interface AudioProviderProps {
  children: ReactNode;
}

const AudioPreviewContext = createContext<VideoPreviewType | undefined>(undefined);

export const AudioPreviewProvider: React.FunctionComponent<AudioProviderProps> = ({ children }) => {
  const [savedType, setSavedType] = useState<'with_timecode' | 'close_window' | null>(null);
  const [selectedAudio, setSelectedAudio] = useState<IMedia | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const handleClose = () => {
    setSelectedAudio(null);
  };

  const handleClearAudioState = useCallback(() => {
    setSavedType(null);
    handleClose();
  }, [handleClose]);

  const AudioPopup = (
    <BasePopUpWrapper>
      <PopupContent>
        <StyledPopupHeader>
          <Typography.Text className="primary-text " style={{ fontSize: '16px', fontWeight: '800' }}>
            Preview Audio
          </Typography.Text>

          <Close
            className="close"
            onClick={() => {
              setSavedType('close_window');
              handleClose();
            }}
          >
            <CloseIcon />
          </Close>
        </StyledPopupHeader>
        {selectedAudio && (
          <PlayerContainer>
            <AudioPlayer audio={selectedAudio} setCurrentTimeCode={setCurrentTime} />
            <StyledBtn onClick={() => setSavedType('with_timecode')} disabled={!currentTime}>
              Add timecode
            </StyledBtn>
          </PlayerContainer>
        )}
      </PopupContent>
    </BasePopUpWrapper>
  );

  const contextValue = useMemo(
    () => ({
      savedType,
      currentTime,

      setSelectedAudio,
      setCurrentTime,
      handleClearAudioState
    }),
    [savedType, currentTime, setSelectedAudio, setCurrentTime, handleClearAudioState]
  );

  return (
    <AudioPreviewContext.Provider value={contextValue}>
      {children}
      {selectedAudio && AudioPopup}
    </AudioPreviewContext.Provider>
  );
};

export const useAudioPreviewContext = () => {
  const context = useContext(AudioPreviewContext);
  if (context === undefined) {
    throw new Error('useVideoPreviewContext must be used within a VideoPreviewProvider');
  }
  return context;
};
