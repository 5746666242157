import React from 'react';
import { FileType, IActionBlock, IMedia, ScriptFormValues } from '../../types';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styled from 'styled-components';

interface Props {
  audios: IMedia[];
  onChange: (audios: IMedia[]) => void;
  setFormValues: React.Dispatch<React.SetStateAction<ScriptFormValues>>;
  setUploadedFiles: React.Dispatch<React.SetStateAction<FileType[]>>;
}

const StyledContainerItem = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 3.81px 15.25px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

interface DragableItemProps {
  audio: IMedia;
  children: React.ReactNode;
}

const DraggableItem: React.FunctionComponent<DragableItemProps> = ({ audio, children }) => {
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({ id: audio.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.6 : 1,
    cursor: 'grab'
  };

  return (
    <StyledContainerItem ref={setNodeRef} style={style} {...attributes}>
      <div {...listeners}>{children}</div>
    </StyledContainerItem>
  );
};

const AudioPreviewer: React.FC<Props> = ({ onChange, setFormValues, setUploadedFiles, audios }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
        delay: 50
      }
    })
  );

  const filterAndReorderActions = (actions: IActionBlock[], audioToRemove: string) => {
    if (!actions.length) return;
    const filteredBlocks = actions?.filter(action => !(action.action_type === 'media_start' && action.id === audioToRemove));
    const grouped = filteredBlocks.reduce(
      (acc, item) => {
        acc[item.action_type] = acc[item.action_type] || [];
        acc[item.action_type].push(item);
        return acc;
      },
      {} as Record<string, typeof filteredBlocks>
    );

    const indexMap: Record<number, number> = {};
    return Object.values(grouped).flatMap(group =>
      group.map((item, idx) => {
        const prevIndex = Number(item.group);
        if (!(prevIndex in indexMap)) {
          indexMap[prevIndex] = Object.keys(indexMap).length;
        }

        return {
          ...item,
          group: `${indexMap[prevIndex]}`
        };
      })
    );
  };

  const onRemove = (audioToRemove: string) => {
    const removedAudio = audios.find(item => item.id === audioToRemove);
    onChange(audios.filter(v => v.id !== audioToRemove));
    setUploadedFiles(prev => prev.filter(item => item.name !== removedAudio?.media_name));
    setFormValues(prev => {
      return {
        ...prev,
        scenario: prev.scenario.map(scenario => ({
          ...scenario,
          blocks: scenario.blocks.map(block => ({
            ...block,
            actions: filterAndReorderActions(block?.actions || [], audioToRemove) || []
          }))
        }))
      };
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over, delta } = event;
    if (delta.x === 0 && delta.y === 0) return;

    if (active.id !== over?.id) {
      const oldIndex = audios.findIndex(audio => audio.id === active.id);
      const newIndex = audios.findIndex(audio => audio.id === over?.id);
      const newAudios = arrayMove(audios, oldIndex, newIndex);

      onChange(newAudios);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Container>
        <SortableContext items={audios.map(audio => ({ id: audio.id }))} strategy={verticalListSortingStrategy}>
          {audios.map(aud => (
            <DraggableItem key={aud.id} audio={aud}>
              <AudioPlayer audio={aud} onRemove={onRemove} />
            </DraggableItem>
          ))}
        </SortableContext>
      </Container>
    </DndContext>
  );
};
export default AudioPreviewer;
