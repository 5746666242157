import React from 'react';
import styled from 'styled-components';
import { AudioIcon, CloseIcon, FileIcon, ReloadIcon, VideoIcon } from '../../../../assets/icons';
import { Spin, Typography } from 'antd';
import { convertBytes } from '../../../../helper';
import { IPresentationTabs } from '../../sections/PresentationSection/PresentationTabs/usePresentationTabs';
import closeIcon from '../../../../assets/close.png';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: grid;
  gap: 15px;
  grid-template-columns: 30px auto min-content;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0.876px 3.505px 0 rgba(0, 0, 0, 0.07);
`;

const StyledVideoIcon = styled(VideoIcon)`
  & > svg {
    width: 28px;
    height: auto;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  > svg {
    > path {
      fill: var(--hint-color);
    }
  }
`;

interface Props {
  completed: boolean;
  file: File & { uid: string };
  type: IPresentationTabs;
  currentUploadedFile?: (File & { uid: string }) | null;
  onRemove: (uid: string) => void;
  isReload: boolean;
  retryFailedUploads?: () => Promise<void>;
  cancelDownloadFile?: (fileUid: string) => void;
}

export const SelectedFile: React.FunctionComponent<Props> = ({
  file,
  onRemove,
  retryFailedUploads,
  cancelDownloadFile,
  completed,
  type,
  isReload,
  currentUploadedFile
}) => {
  return (
    <Spin spinning={!completed}>
      <Container>
        {type === 'presentation' && <FileIcon className="violet-solid" />}
        {type === 'video' && <StyledVideoIcon className="violet-solid" />}
        {type === 'audio' && <AudioIcon className="violet-solid" />}

        <div>
          <Typography className="bold-14 primary-color">{file.name}</Typography>
          <Typography className="bold-1 violet-solid">{convertBytes(file.size)}</Typography>
        </div>
        {isReload && currentUploadedFile?.uid === file.uid && (
          <ReloadIcon className="hint-color cursor-pointer" onClick={retryFailedUploads} />
        )}
        {!isReload && currentUploadedFile?.uid === file.uid && cancelDownloadFile && (
          <StyledCloseIcon onClick={() => cancelDownloadFile(file.uid)} />
        )}
      </Container>
    </Spin>
  );
};
