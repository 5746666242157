import { message, UploadProps } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FileType, ProgressType, IMedia } from '../../../types';
import { uploadFiles, uploadMediaFile, uploadMediaFileS3 } from '../../../api/apiWorker';
import { AxiosResponse } from 'axios';

type MetaData = {
  duration: number | null;
  thumbnail: string;
};

type IncommingMediaType = 'video' | 'audio' | 'builds';

export const useUploadMedia = ({
  isShowUploader,
  onChange,
  type,
  multipleLoader = true,
  acceptFormats = '*/*'
}: {
  isShowUploader: boolean;
  onChange: (media: IMedia) => void;
  type: IncommingMediaType;
  multipleLoader?: boolean;
  acceptFormats?: string;
}) => {
  const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileType[]>([]);

  const [currentUploadedFile, setCurrentUploadedFile] = useState<FileType | null>(null);
  const [progress, setProgress] = useState<ProgressType>({ percent: undefined, status: undefined });
  const fileCounter = useRef(0);
  const isBeforeUploaded = useRef(false);

  const allowedMediaTypes = type === 'video' ? ['video/mp4', 'video/mov', 'video/quicktime'] : ['audio/mpeg'];

  const beforeUpload = (file: FileType, fileList: FileType[]) => {
    if (type === 'builds') {
      setSelectedFiles(fileList);
      return true;
    }
    setSelectedFiles(prev => prev.filter(f => f.uid !== currentUploadedFile?.uid));

    if (isBeforeUploaded.current) return false;
    const unsupportedFile = fileList.find(f => !allowedMediaTypes.includes(f.type));
    if (unsupportedFile) {
      message.error(`File ${unsupportedFile.name} is not supported`);
      setProgress({ percent: 0, status: 'exception' });
      return false;
    }

    const totalSize = fileList.reduce((total, currentFile) => total + currentFile.size, 0);
    if (totalSize / 1024 / 1024 >= 500) {
      fileList.length < 2
        ? message.error('File size must be smaller than 500MB!')
        : message.error('Total file size must be smaller than 500MB!');
      setProgress({ percent: 0, status: 'exception' });
      return false;
    }

    setSelectedFiles(fileList);
    isBeforeUploaded.current = true;
    return true;
  };

  const handleGetThumbnailAndDuration = async (file: FileType, type: IncommingMediaType) => {
    if (!file || type !== 'video') return { thumbnail: '', duration: null };

    return new Promise<{ thumbnail: string | null; duration: number | null }>(resolve => {
      let thumbnail: string | null = null;
      let duration: number | null = null;

      const objectUrl = URL.createObjectURL(file);
      const video = document.createElement('video');
      video.crossOrigin = 'anonymous';
      video.preload = 'auto';
      video.muted = true;
      video.playsInline = true;
      video.src = objectUrl;

      const captureFrame = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
        thumbnail = canvas.toDataURL('image/jpeg');

        video.pause();
        video.remove();
        URL.revokeObjectURL(objectUrl);

        resolve({ thumbnail, duration });
      };

      video.onloadedmetadata = () => {
        duration = video.duration;
        video.currentTime = 0.2;
      };

      if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
        video.requestVideoFrameCallback(() => {
          captureFrame();
        });
      } else {
        video.onseeked = () => {
          if (video.readyState >= 2) {
            captureFrame();
          } else {
            video.onloadeddata = captureFrame;
          }
        };

        video.onloadeddata = () => {
          if (!thumbnail) {
            video.currentTime = 0.1;
            setTimeout(() => {
              if (!thumbnail) {
                captureFrame();
              }
            }, 100);
          }
        };
      }
    });
  };

  const dataURLToFile = (dataURL: string, filename: string): File => {
    const [header, base64Data] = dataURL.split(',');
    const mimeType = header.split(':')[1].split(';')[0];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset++) {
      byteArrays.push(byteCharacters.charCodeAt(offset));
    }

    const uint8Array = new Uint8Array(byteArrays);
    return new File([uint8Array], filename, { type: mimeType });
  };

  const abortControllersRef = useRef(new Map<string, AbortController>());

  const handleUpload = useCallback(
    async (options: any) => {
      const { onSuccess, onError, onProgress } = options;

      let metaData: MetaData = {
        duration: null,
        thumbnail: ''
      };

      try {
        for (const file of selectedFiles) {
          try {
            const mediaMeta = await handleGetThumbnailAndDuration(file, type);

            if (mediaMeta.thumbnail) {
              const thumbnailUrlFile = dataURLToFile(mediaMeta.thumbnail, file.name);
              const formDataЕhumbnail = new FormData();
              formDataЕhumbnail.append('file', thumbnailUrlFile);
              const data = await uploadFiles(formDataЕhumbnail);
              if (data?.length) {
                metaData = { thumbnail: data[0].file_url, duration: mediaMeta.duration };
              }
            }
          } catch (e) {
            console.error('Error getting video preview:', e);
          }
          setCurrentUploadedFile(file);
          let startProgress = 10;

          const abortController = new AbortController();
          abortControllersRef.current.set(file.uid, abortController);
          const { signal } = abortController;

          const startInterval = setInterval(() => {
            startProgress += 5;
            if (startProgress >= 40) {
              clearInterval(startInterval);
              return;
            }
            setProgress({ percent: startProgress, status: 'active' });
            onProgress({ percent: startProgress });
          }, 1000);
          setUploadedFiles(prev => [...prev, file]);

          let response: AxiosResponse<any>;

          try {
            response = await uploadMediaFile(file.name, { signal });
          } catch (e) {
            if (signal.aborted) {
              clearInterval(startInterval);
              message.warning(`Upload canceled for ${file.name}`);
              setProgress({ percent: 40, status: 'exception' });
              onProgress({ percent: 50, status: 'exception' });
              setSelectedFiles(prev => prev.filter(f => !uploadedFiles.some(uploadedFile => uploadedFile.uid === f.uid)));
              return;
            }
            clearInterval(startInterval);

            throw e;
          }

          const data = response.data;
          const fileUrl = data.file_url;
          const url = data.url;
          const fields = data.fields as Record<string, string>;
          const formData = new FormData();

          for (const [key, value] of Object.entries(fields)) {
            formData.append(key, value);
          }
          formData.append('file', file);
          type === 'video' ? formData.append('Content-Type', 'video/mp4') : formData.append('Content-Type', 'audio/mpeg');

          let currentProgress = 40;
          const interval = setInterval(() => {
            currentProgress += 10;
            if (currentProgress >= 90) {
              clearInterval(interval);
              return;
            }
            setProgress({ percent: currentProgress, status: 'active' });
            onProgress({ percent: currentProgress });
          }, 1000);

          try {
            await uploadMediaFileS3(url, formData, { signal });
            clearInterval(interval);
            clearInterval(startInterval);
            setProgress({ percent: 100, status: 'success' });
            onProgress({ percent: 100 });
            setTimeout(() => setProgress({ percent: 100, status: 'normal' }), 500);

            onChange({
              media_type: type as IMedia['media_type'],
              media_url: fileUrl,
              id: data.upload_id,
              media_name: file.name,
              media_length: metaData.duration ? Math.floor(metaData.duration) : 0,
              media_thumbnail: metaData.thumbnail
            });

            message.success(`File ${file.name} uploaded successfully`);

            fileCounter.current++;
            abortControllersRef.current.delete(file.uid);
            setSelectedFiles(prev => prev.filter(f => f.uid !== file.uid));
            setCurrentUploadedFile(null);
            if (fileCounter.current === selectedFiles.length) {
              onSuccess();
              setSelectedFiles([]);
            }
          } catch (e) {
            if (signal.aborted) {
              clearInterval(interval);
              clearInterval(startInterval);
              message.warning(`Upload canceled for ${file.name}`);
              setProgress({ percent: 40, status: 'exception' });
              onProgress({ percent: 40, status: 'exception' });
              setSelectedFiles(prev => prev.filter(f => !uploadedFiles.some(uploadedFile => uploadedFile.uid === f.uid)));

              return;
            }
            clearInterval(interval);
            clearInterval(startInterval);
            throw e;
          }
        }
      } catch (e) {
        message.error("File wasn't uploaded");
        setSelectedFiles(prev => prev.filter(f => !uploadedFiles.some(uploadedFile => uploadedFile.uid === f.uid)));

        onError();
        setProgress({ percent: 40, status: 'exception' });
        onProgress({ percent: 40, status: 'exception' });
      } finally {
        isBeforeUploaded.current = false;
        fileCounter.current = 0;
      }
    },
    [selectedFiles.length]
  );

  const showBigUploader = useMemo(() => {
    if (type === 'builds') return false;
    return !!uploadedFiles.length || isShowUploader;
  }, [uploadedFiles, isShowUploader]);

  const cancelDownloadFile = (fileUid: string) => {
    const controller = abortControllersRef.current.get(fileUid);
    if (controller) {
      controller.abort();
      abortControllersRef.current.delete(fileUid);
    }
  };

  const retryFailedUploads = async () => {
    setUploadedFiles(prev => prev.filter(f => f.uid !== currentUploadedFile?.uid));
    handleUpload({ onSuccess: () => {}, onError: () => {}, onProgress: () => {} });
  };

  const uploadProps: UploadProps = {
    name: `script/${type}`,
    multiple: multipleLoader,
    beforeUpload: beforeUpload,
    customRequest: handleUpload,
    accept: acceptFormats,
    style: { padding: '16px 60px', display: showBigUploader ? 'none' : 'block' },
    fileList: []
  };

  return {
    uploadPropsMedia: uploadProps,
    uploadedMediaFiles: uploadedFiles,
    allowedMediaTypes,
    progressMedia: progress,
    showBigUploaderMedia: showBigUploader,
    currentUploadedFile,
    setUploadedFiles,
    retryFailedUploads,
    cancelDownloadFile,
    handleUpload,
    setSelectedFiles
  };
};

export default useUploadMedia;
